@import "../variables";

$panel-vertical-margin: 16px;
$scroll-horizontal-padding: 40px;
$scroll-vertical-padding: 20px;
$scroll-min-height: 200px;
$list-header-height: 105px;
$form-header-height: 95px;

.oc-page {
    .oc-page__content {
        position: relative;
        background: #fff;
    }

    .oc-content-panel > .panel-heading {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.oc-page-list {
    .oc-page__content {
        height: calc(100vh - #{$list-header-height + $global-header-height});
        top: $list-header-height;
    }

    .header-section__inside {
        display: flex;
        align-items: center;
        padding-left: $scroll-horizontal-padding;
        padding-right: $scroll-horizontal-padding;

        .header-section__title {
            padding-left: 0;
        }
    }

    .oc-list-header-filters {
        padding-bottom: $panel-vertical-margin;

        .oc-list-header-filters__search {
            width: 300px;
        }

        .oc-list-header-filters__sort {
            width: 300px;
            margin-left: auto;
        }
    }
}

.oc-page-form {
    .oc-page__content {
        height: calc(100vh - #{$form-header-height + $global-header-height});
        top: $form-header-height;
        padding: 0 $scroll-horizontal-padding;
        background: $bg-silver;
        overflow-y: scroll;
    }

    .header-section__inside {
        display: flex;
        align-items: center;
        padding-left: $scroll-horizontal-padding;
        padding-right: $scroll-horizontal-padding;

        button {
            min-width: 50px;
        }

        .oc-page-btn-container {
            button {
                min-width: 0px !important;
            }
        }
    }


}

.oc-list {
    position: relative;
    background: $bg-silver;
    height: inherit;

    .oc-list__scroll-container {
        position: relative;
        width: 100%;
        padding: 0 $scroll-horizontal-padding;
        overflow-y: scroll;
        height: inherit;
    }

    .oc-list__content {
        position: relative;
        min-height: $scroll-min-height;
        margin: $panel-vertical-margin 0;
    }

    .spinner-overlay {
        background: $bg-silver;
        min-height: $scroll-min-height;
    }

    .campaign-overview {
        display: flex;
    }

    .campaign-info {
        width: 40%;
        border-right: 1px solid lightgrey;
        padding: 10px;
    }

    .campaign-schedule {
        width: 60%;
        padding: 20px;
    }

    .campaign-state {
        pointer-events: none;
        border: none;
    }
}

.oc-tab-content {
    .oc-tab__title {
        margin-bottom: 20px;
        margin-top: 40px;
    }
}

.oc-tab-audience {
    margin-top: 20px;
}

.oc-content-item-panel {
    padding: 10px 0;
    margin-bottom: 0 !important;

    .el-divider--vertical {
        height: auto;
    }

    .oc-content-item-panel__actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn {
            height: 34px;
        }
    }
}

.oc-email-panel {
    .oc-email-panel__send-time-column {
        display: flex;
        flex-direction: column;
        width: 210px;
    }

    .oc-email-panel__preview-img {
        display: block;
        max-height: 50px;
        height: auto;
        width: 50px;
        margin-right: 15px;
    }
}

.oc-settings-panel {
    padding: 10px 0;
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
