.lg-outer {
    z-index: 1051;
}

.lg-backdrop {
    z-index: 1050;
}

#lg-actual-size:after {
    content: "\f24d";
    font-family: FontAwesome;
}

#lg-zoom-out:after {
    content: "\f010";
    font-family: FontAwesome;
}

#lg-zoom-in:after {
    content: "\f00e";
    font-family: FontAwesome;
}

.lg-autoplay-button:after {
    content: "\f04b";
    font-family: FontAwesome;
}

.lg-fullscreen:after {
    content: "\f0b2";
    font-family: FontAwesome;
}

.lg-toolbar .lg-close:after {
    content: "\f00d";
    font-family: FontAwesome;
}

#lg-download:after {
    content: "\f019";
    font-family: FontAwesome;
}

.lSPrev,
.lg-actions .lg-prev:after {
    content: "\f060";
    font-family: FontAwesome;
}

.lSNext,
.lg-actions .lg-next:before {
    content: "\f061";
    font-family: FontAwesome;
}

.lg-outer .lg-toogle-thumb:after {
    content: "\f03a";
    font-family: FontAwesome;
}

.lSPrev,
.lSNext {
    color: #fff;
    font-size: 25px;
}

.lSPrev:hover,
.lSNext:hover {
    color: #fff;
}

.light-slider__img {
    width: 100%;
    height: auto;
}

.listed_by_details {
    color: #fff;
    text-shadow: 0 0 3px rgba(0,0,0,.5);
}

.light-slider__item {
    position: relative;
}

.light-slider__img {
    width: 100%;
    height: auto;
}