/* Contact Chip
================================================== */

.chip {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #f4f6f7;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
}

.chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.chip-sm {
    padding: 0 15px 0 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
}

.chip-no-image {
    padding: 0 15px 0 15px;
}

.chip-sm img {
    margin: 0 10px 0 -25px;
    height: 25px;
    width: 25px;
}

.chip-mobile {
    display: none;
}

@media only screen and (max-width: 375px) {

    .chip-desktop {
        display: none;
    }

    .chip-mobile {
        display: inline-block;
    }
}