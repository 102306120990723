.overflow-x-only {
    overflow-x: auto;
    overflow-y: hidden;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-hidden {
    overflow: hidden;
}

.no-shadow {
    box-shadow: none !important;
}

.mid-shadow {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.mh-300 {
    min-height: 300px;
}

.inherit-color {
    color: inherit;
}

button.btn.facebook {
    background-color: #4267b2;
    border-color: initial;
    border-radius: 5px;
    color: white;
    text-align: center;
    width: 250px;
    height: 40px;
    line-height: 1.86em;
}

button.btn.facebook i.fa.fa-facebook-square {
    float: left;
}

button.btn.facebook i.fa.fa-facebook-square:before {
    font-family: fontAwesome;
    font-size: 1.8em;
    line-height: 1em;
}

button.btn.facebook.disabled .fa.fa-facebook-square {
    display: none;
}

.font-facebook-blue {
    color: #3B5998;
}

.font-twitter-blue {
    color: #00aced;
}

.font-linkedin-blue {
    color: #0e76a8;
}

.b-r-3 {
    border-radius: 3px;
}

.true-inline {
    display: inline;
}

.m-w-150 {
    max-width: 150px;
}

.m-w-130 {
    max-width: 130px;
}

.v-a-bottom {
    vertical-align: bottom;
}

.v-a-middle {
    vertical-align: middle;
}

.v-center {
    vertical-align: middle;
    margin-bottom: auto;
    margin-top: auto;
}

.mw-300 {
    min-width: 300;
}

.z-index-0 {
    z-index: 0 !important;
}

.z-index-1 {
    z-index: 1;
}

.no-wrap {
    white-space: nowrap;
}

.wrap {
    white-space: normal;
}

.clear-hide {
    overflow: hidden !important;
}

.visible {
    overflow: visible !important;
}

.relative {
    position: relative;
}

@media only screen and (max-width: 767px) {

    .hidden-xs {
        display: none !important;
    }
}

@media only screen and (max-width: 415px) {

    .hidden-phone {
        display: none !important;
    }
}

.clear-auto {
    overflow: auto;
}

.overflow-x {
    overflow-x: scroll;
}

.inline {
    display: inline-block;
}

.panel-500 {
    max-height: 500px;
    overflow: auto;
}

.panel-400 {
    height: 400px;
    overflow-y: scroll;
}

.panel-300 {
    height: 300px;
    overflow-y: scroll;
}

.truncate {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-fit-email-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 2px;
}

.truncate-fit {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 2px;
}

.truncate-md {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 2px;
}

.truncate-sm {
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.radius-none {
    border-radius: 0;
}

.ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

.center {
    margin: 0 auto;
    display: table;
}

.left-align {
    text-align: left;
}

.text-silver-lighter { color: #f4f6f7 !important; }
.text-silver { color: #f0f3f4 !important; }
.text-silver-darker { color: #b4b6b7 !important; }

.text-black { color: #2d353c !important; }
.text-black-darker { color: #242a30 !important; }
.text-black-lighter { color: #575d63 !important; }

.text-grey { color: #b6c2c9 !important; }
.text-grey-primary { color: #707478 !important; }
.text-grey-darker { color: #929ba1 !important; }
.text-grey-lighter { color: #c5ced4 !important; }

.text-red { color: #ff5b57 !important; }
.text-red-darker { color: #cc4946 !important; }
.text-red-lighter { color: #ff7c79 !important; }

.text-orange { color: #f59c1a !important; }
.text-orange-darker { color: #c47d15 !important; }
.text-orange-lighter { color: #f7b048 !important; }

.text-yellow { color: #e3fa3e !important; }
.text-yellow-darker { color: #b6c832 !important; }
.text-yellow-lighter { color: #e9fb65 !important; }

.text-green { color: #00acac !important; }
.text-green-darker { color: #008a8a !important; }
.text-green-lighter { color: #33bdbd !important; }

.text-blue { color: #348fe2 !important; }
.text-blue-darker { color: #2a72b5 !important; }
.text-blue-lighter { color: #5da5e8 !important; }

.text-aqua { color: #49b6d6 !important; }
.text-aqua-darker { color: #3a92ab !important; }
.text-aqua-lighter { color: #6dc5de !important; }

.text-purple { color: #727cb6 !important; }
.text-purple-darker { color: #5b6392 !important; }
.text-purple-lighter { color: #8e96c5 !important; }

.text-bold {
    font-weight: 600;
}

.bg-transparent {
    background: transparent;
}

.color--transparent {
    color: transparent;
}

.bg-magenta {
    background: #4a3553;
}

.grey-bg {
    background: #d9e0e7;
}

.white-bg {
    background: #fff;
}

.mm-purple-bg {
    background: #5c2d91;
}

.mm-green-bg {
    background: #A6CE38;
}

.mm-light-grey-bg {
    background: #ccccce;
}

.mm-dark-grey-bg {
    background: #535355;
}

.mm-dark-grey {
    color: #535355 !important;
}

.mm-purple {
    color: #5c2d91 !important;
}

.mm-green {
    color: #A6CE38 !important;
}

.mm-text-grey, .mm-text-grey .stats-title {
    color: #535355;
}

.iframe-wrapper {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.iframe-modal,
.imo-form-iframe {
    height: calc(100vh - 169px);
}

.scroll-div {
    overflow-y:auto;
}

.light-grey-bg {
    background: #f5f5f5;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.new-clearfix::before, .new-clearfix::after {
    content:"";
    display:table;
}

.new-clearfix::after {
    clear:both;
}

.new-clearfix {
    zoom:1; /* For IE 6/7 (trigger hasLayout) */
}

.red-dot {
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 50%;
}

.btn .red-dot {
    position: absolute;
    top: -5px;
    right: -5px;
    border: 2px solid #fff;
}

.btn .badge-count,
.filter-count {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 22px;
    height: 22px;
    background: #348fe2;
    color: #fff;
    border: 1px solid #348fe2;
    border-radius: 50%;
    line-height: 20px;
    font-weight: bold;
    z-index: 1;
    text-align: center;
}

.m-r-7 {
    margin-right: 7px !important;
}

.capitalize {
    text-transform: capitalize;
}

.f-s-0 {
    font-size: 0;
}

.f-s-1 {
    font-size: 1em;
}

.img-responsive-full {
    display: block;
    width: 100%;
    height: auto;
}

.v-a-t {
    vertical-align: top;
}

.border-red {
    border: red solid 1px;
}

.border-none {
    border: none;
}

.b-l-1 {
    border-left: solid 1px #eee;
}

.b-r-1 {
    border-right: 1px solid #eee;
}

.p-t-b-10 {
    padding: 10px 0;
}

@media only screen and (max-width: 340px) {
    .truncate {
        width: 200px;
        height: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}

.overflow-ellipsis-20 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(20vw);
}