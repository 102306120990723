/* Login Page styles for other companies' custom login pages
================================================== */

#page-container {
    min-height: 500px;
    height:100%;
}

.brand-split {
    overflow: hidden;
    margin: 0 auto;
    display: table;
}

.brand-split-item {
    float: left;
}

.split-line {
    float: left;
    height: 65px;
    background: rgba(255,255,255,0.40);
    width: 1px;
    margin: 0 15px;
}

.brand-split-item img {
    height: 65px;
    width: auto;
}

.brand-split-item.te-logo img {
    height: 45px;
    margin-top: 9px;
}

.login-logo {
    margin: 0 auto;
    display: table;
    margin-bottom: 10px;
}

.login-logo img {
    height: 60px;
}

.login-cover-image {
    background:url(./images/login-background/minneapolis_golden.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.login .login-content {
    padding: 30px 40px;
    color: #999;
    width: 450px;
    margin: 0 auto;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12);
}

.login-v2 {
    width: 450px;
    background: #fff;
    position: relative;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
    text-align: center;
    z-index: 9999;
}

.login {
    margin: 0;
}

.login-v2 .form-control {
    background: url(./assets/img/transparent/black-0.4.png);
    background: #f5f5f5;
    border: 1px solid #d6d6d6;
    color: #333 !important;
}

.login.login-v2 .login-header, .login.login-v2 a {
    color: #348fe2;
}

.login-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    /*background: rgba(0,0,0,.4);*/
    width: 100%;
    height: 70px;
}

.login-footer__logo img {
    height: 45px;
    margin: 13px 0;
    padding: 0 10px;
}

.login-footer__logo {
    display: inline-block;
}

.login-footer__item a {
    color: #fff;
    padding: 0 10px;
}

.login-footer__item {
    display: inline-block;
}

.login-footer__center {
    margin: 0 auto;
    display: table;
}

@media (max-width: 768px) {

    .login.login-v2 {
        margin: 0;
        width: 90%;
    }

    .login-logo img {
        height: 45px;
    }
}