.hm-form-item .hm-form-item--lead-update {
    margin: 0;
}

.hm-form-item--label {
    display: inherit;
}

.hm-form-item--info-text {
    font-size: 12px;
    line-height: 24px;
}

.hm-household-panel__top{
    margin: 0 0 12px 0;
}

.hm-household__left{
    float: left;
}

.hm-household__right{
    float: right;
}

.hm-household-member__left,
.hm-household-member__right {
    display: inline-block;
}

.hm-household-member__left + .hm-household-member__left:before,
.hm-household-member__left + .hm-household-member__right:before,
.hm-household-member__right + .hm-household-member__right:before{
    content: ", ";
}

.hm-household-member__right:first-of-type {
    margin-left: -3px;
}

.hm-select-infinite {
    margin-bottom: 10px;
}

.hm-select-infinite .el-select__tags span .el-tag--primary{
    display: none;
}

.hm-select-infinite .el-select__folders span .el-folder--primary{
    display: none;
}

.hm-form-item--contact-list{
    display: table;
    width: 100%;
    clear: both;
    line-height: 15px;
    margin-top: 10px;
}

.hm-contact-list--member-info{
    float: left;
    width: 50%;
}

.hm-contact-list--primary-member-select{
    float: right;
    width: 50%;
}

.hm-contact-list--primary-member-select .el-radio{
    float: right;
}

.hm-contact-list--primary-member-select label {
    margin: 0;
}

.hm-contact__hr{
    width:100%;
    margin: 2px 0 4px 0;
    display: inline-block;
}

.hm-form-item--contact-list:last-of-type .hm-contact__hr{
    display: none;
}

.hm-lead-search--icon{
    position: absolute;
    width: auto;
    color: gray;
    background-color: transparent;
    font-size: 16px;
    line-height: 28px;
    border: 0;
    right: 0;
    top: 1px;
    z-index: 45;
    border-radius: 0 2px 2px 0;
    padding: 0 12px;
    height: 36px;
}

.hm-lead-search--placeholder-text{
    position: absolute;
    top: 0;
    left: 10px;
    pointer-events: none;
}

.hm_body {
    padding: 15px 15px 0 15px !important;
}
