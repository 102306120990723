
.data-box__stat--medium {
    font-size: 28px !important;
    font-weight: 300 !important;
}

.data-box__label {
    color: $text-grey;
    font-size: 15px;
    font-weight: 300;
    display: flex;
    align-items: baseline;

    &.is-vertical {
        flex-direction: column;
    }

    &.is-horizontal {
        flex-direction: row;

        .data-box__label-small {
            margin-left: 5px;
        }
    }
}