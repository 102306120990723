.bwizard-steps li.active, .bwizard-steps li.active:focus, .bwizard-steps li.active:hover {
    background: #0193d0 !important;
}

.bwizard-steps li.active a {
    color: #fff;
}

.bwizard-steps {
    margin-bottom: 15px;
}