.list-box__one,
.list-box__two {
    float: left;
}

.list-box__two {
    margin: 2px 0 2px 15px;
}

.list-box__two-title {
    font-size: 16px;
    color: #333;
}

.data-box__stat {
    margin-top: 0;
}

.loan-stat {
    border-bottom: 1px solid #d9e0e7;
    margin: 1px;
    padding: 5px 0 8px;
}

@media only screen and (max-width: 767px) {
    .lead-info-box {
        height:auto;
    }
}

@media only screen and (max-height: 1080px) {
    ul.leads-action-menu {
        height: auto;
        max-height: 600px;
        overflow-y: scroll;
    }
}