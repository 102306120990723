.top-ribbon {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    left: 0;
    top: 54px;
    right: 0;
    height: $top-ribbon-height;
    padding: 7px 25px;
    background: #fff;
    position: fixed;
    width: 100%;
    z-index: 1002;

    .btn-group-sm > .btn,
    .btn-sm {
        vertical-align: top;
    }
}

.top-ribbon--border-bottom {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #eee;
}

.input--ribbon-search {
    display: inline-block;
    width: 150px !important;

    @media (min-width: $iphone-plus) {
        width: 200px !important;
    }
}

.ribbon-btn-container {
    height: 100%;
}

.top-ribbon__select-count {
    font-size: 14px;
    padding: 5px 0;
}

.top-ribbon--relative {
    width: 100% !important;
    right: 0;
    left: 0 !important;
    top: 0 !important;
    position: relative;
    overflow: hidden;
    z-index: 10;
}

.el-dialog__body {

    .top-ribbon {
        width: 100% !important;
        right: 0;
        left: 0 !important;
        top: 0;
        position: relative;
        padding: 7px 15px;
    }
}

.ribbon-btn-container {
    position: relative;
}

.ribbon-btn-container.content {
    padding: 0;
}

.top-ribbon__title {
    padding: 0 20px;
    font-size: 14px;
    color: #333;
    font-weight: 600;
    line-height: 2.1;
}

@media screen and (max-width: 768px) {

    .top-ribbon {
        padding: 7px 10px;
    }
}
