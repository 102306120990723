.gjs-pn-views-container {
    display: none;
}

.gjs-cv-canvas {
    left: auto;
    right: 0;
    top: 0;
    height: 100%;
}

.gjs-pn-panels {
    display: none !important;
}

.gjs-toolbar {
    display: none;
}

/*need to move this to be passed into grapes css*/
.gjs-comp-selected.mce-content-body {
    outline: 1px dashed #3b97e3 !important;
    outline-offset: -3px;
    outline-offset: 2px !important;
}

.gjs-placeholder,
.gjs-nv-placeholder {
    border-color: #348fe2;
}

.gjs-placeholder-int,
.gjs-com-placeholder-int,
.gjs-nv-placeholder-int {
    background-color: #348fe2;
    height: 100%;
    width: 100%;
    pointer-events: none;
    padding: 1.5px;
    outline: none;
}

.gjs-block-label {
    font-size: 13px;
    font-family: 'Open Sans',Arial,sans-serif;
}

.gjs-blocks-c {
    background-color: $bg-silver;
}

.tc-block {
    width: 100%;
    padding: 0;
    border-radius: 3px;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: $bg-silver;
    overflow: initial;
    min-height: auto;
    margin: 0 0 15px 0;

    &:hover {
        box-shadow: none;
    }

    .gjs-block-label {
        background: $bg-silver;
        text-align: left;
        overflow: initial;
    }
}

.tc-block__img {
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    transition: all 0.2s ease 0s;
    transition-property: box-shadow, color;
    min-height: 50px;
    background: rgba(255,255,255,0.4);

    &:hover {
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
    }

    /* Prevent the block preview from overwhelming the editor */
    & .img-responsive-full {
        max-height: 250px;
        object-fit: scale-down;
    }
}

.tc-loader {
    top: 0;
    margin: 0 34px;
    height: calc(100vh - 138px)
}

.flex-grid-tile-icon {
    padding: 11px;
}

.tc-grid-icon {
    background-color: transparent;
    border-color: $black;
    border-width: 2px;
    border-radius: 3px;
    border-style: solid;
    height: 32px;
    margin: 0px 2px;
}