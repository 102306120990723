#upload,
#library-upload {
    background-color: #fff;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 20px;
}

#drop,
#library-drop {
    width: 100%;
    padding: 20px 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    color: #7f858a;
    border: 2px dashed #d6d6d6;
    border-radius: 3px;
}

#drop input,
#library-drop input {
    display: none;
}

#upload ul,
#library-upload ul {
    list-style: none;
    margin: 0;
    background-color: #FFF;
    padding: 0;
}

#upload ul li,
#library-upload ul li {
    background-color: #FFFFFF;
    padding: 15px;
    height: 52px;
    position: relative;
}

#upload ul li input,
#library-upload ul li input {
    display: none;
}

#upload ul li p,
#library-upload ul li p {
    width: 130px;
    height: auto;
    white-space: nowrap;
    color: #333;
    font-size: 10px;
    position: absolute;
    top: 0;
    left: 12px;
}

#upload ul li i,
#library-upload ul li i {
    font-weight: normal;
    font-style: normal;
    color: #7f7f7f;
    display: block;
}

#upload ul li canvas,
#library-upload ul li canvas {
    top: 15px;
    left: 6px;
    position: absolute;
}

#upload ul li span,
#library-upload ul li span {
    width: 15px;
    height: 12px;
    background: url('./images/icons.png') no-repeat;
    position: absolute;
    top: 18px;
    right: 6px;
    cursor: pointer;
}

#upload ul li.working span,
#library-upload ul li.working span {
    height: 16px;
    background-position: 0 -12px;
}

#upload ul li.error p,
#library-upload ul li.error p {
    color: red;
}

.library-drop__info {
    padding-bottom: 10px;
}

.library-drop__guidelines {
    font-size: 12px;
    padding-bottom: 10px;
}