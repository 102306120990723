.co-marketer-item {
    margin-bottom: 10px;
}

.co-marketer-item__name {
    color: #333;
    margin: 6px 0;
    display: block;
    padding-left: 5px;
}

.image-gallery {
    list-style-type: none;
    padding:0;
}

.gallery-item {
    border:2px #ccc solid;
    margin:5px 0;
    padding:5px;
    cursor:move;
}

#co-marketers-box{
    list-style-type:none;
    padding-left:0;
}

#co-marketers-box h3{
    margin:5px;
}

#co-marketers-box li{
    cursor:move;
}

.co-marketer-item{
    margin:3px;
    padding:3px
}

.control-label-description--print {
    display: block;
    margin-top: -5px;
    margin-bottom: 5px;
}

.select-print-thumb {
    width: 100%;
    height: auto;
    max-width: 175px;
    position: relative;
}

.sps-icon-bar,
.sps-form {
    height: 100%;
    float: left;
}

/*NEW Stuff*/

.sps-icon-bar__link[aria-expanded="true"] > .sps-tooltip  {
    display: none;
}

.sps-icon-bar__link[aria-expanded="false"] > .fa-chevron-left {
    display: none;
}

.sps-icon-bar__link {
    display: block;
    width: 70px;
    height: 70px;
    text-align: center;
    vertical-align: baseline;
    font-size: 25px;
    padding: 17px;
    color: #333;
    background: #fff;
    /*transform: translate(0, 100%);*/
    transition: all .2s ease-in-out;
}

a.sps-icon-bar__link:hover,
a.sps-icon-bar__link:focus {
    color: #333;
}

.sps-icon-bar__link.collapsed {
    background: transparent;
    color: #929ba1;
}

.sps-sidebar {
    height: calc(100vh - 98px);
    margin: -20px -25px;
    position: absolute;
    z-index: 1;
}

.sps-icon-bar {
    width: 70px;
    height: 100%;
}

.panel-group-sps,
.sps-icon-bar {
    display: inline-block;
    height: 100%;
}

.panel-group-sps {
    margin: 0;
    position: relative;
}

.panel-group-sps .panel {
    border-radius: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.panel-sps {
    height: 100%;
    width: 300px;
    background: #fff;
    display: none;
}

.panel-group-sps .panel+.panel {
    margin-top: 0;
}

.panel-sps.active {
    display: block;
}

.sps-preview {
    width: 100%;
    height: 100%;
    position: relative;
    padding-left: 370px;
    float: left;
    z-index: 0;
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    height: calc(100vh - 138px);
}

.panel-group-sps .panel-body {
    padding-bottom: 70px;
}

.sps-panel-pager {
    width: 100%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #ddd;
    background: #fff;
    padding: 8px 15px;
    z-index: 5;
}

.panel-group-sps {
    margin: 0;
    position: relative;
    z-index: 0;
}

.sps-icon-bar {
    position: relative;
    z-index: 2;
}

.sps-listings-tab {
    overflow: auto;
    padding-bottom: 25px;
}

.sps-preview__scroll {
    overflow: scroll;
}

a.js-remove-gallery-image.btn.btn-sm.btn-danger.pull-right {
    position: absolute;
    top: 8px;
    right: 8px;
}

.gallery-item {
    position: relative;
}

img.js-gallery-image {
    width: 100%;
    max-width: 250px;
}

.select-print-thumb {
    position:relative;
}
.select-print-thumb .remove-image-button {
    position:absolute;
    top:0;
    right:0;
}

@media only screen and (max-width: 767px) {

    .js-auto-fit-container, .js-zoom-slider-container {
        display: none;
    }

    .sps-sidebar {
        margin: 0 -10px;
        position: fixed;
    }

    .sps-icon-bar {
        width: 50px;
    }

    .sps-icon-bar__link {
        width: 50px;
        height: 50px;
        font-size: 18px;
        padding: 13px;
    }

    .sps-preview {
        padding-top: 15px;
        padding-left: 70px;
    }
}