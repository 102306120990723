.stat-percent-compare-panel {
    .stat-percent-compare-panel__progress-bar-wrapper {
        display: inline-block;
        margin-top: 7px;
        margin-bottom: 0px;
    }

    .stat-percent-compare-panel__title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .stat-percent-compare-panel__label {
        font-size: 14px;
    }

    .stat-ratio {
        margin-left: 10px;
    }
    table {
        width:100%;
        border-collapse: collapse;
    }
    table td {
        white-space:nowrap;
        padding-right: 10px;
    }
    table td:nth-child(3) {
        width:99%;
    }
    table td:last-child {
        padding-right:40px;
    }
}

/* Not included above because the tooltip is in a separative div on the body */
.stat-percent-compare-panel__tooltip {
    text-align: center;
}