.el-select-dropdown,
.el-dropdown-menu {
    border-radius: $base-radius;
    box-shadow: $dropdown-shadow;
    border: 0;
}

.el-select-dropdown__item,
.el-dropdown-menu__item {
    padding: 5px 25px;
    line-height: 1.42857143;
    color: $black;

    &:not(.is-disabled):hover {
        background: $dropdown-hover-color;
        color: $black;
    }

    a {
        color: $black;
    }
}

.el-dropdown-menu__item--divided {
    &:before {
        background-color: inherit;
    }
}

/*Bootstrap Dropdown*/

.dropdown-menu > li > a {
    padding: 5px 25px;
    text-align: left;
    font-size: 1.1em;
}

.dropdown-header-container {
    position: relative;
}

.dropdown-header-container hr {
    margin: 0;
    margin-top: 7px;
    position: absolute;
    left: 1rem;
    right: 0;
    margin-top: .9rem;
    margin-bottom: 0;
}

.dropdown-label {
    font-size: 1em;
    font-weight: 500;
    padding: 0;
    text-align: left;
    margin: 0;
    color: #9e9ea6;
    padding-left: 10px;
    padding-right: 6px;
    background: #fff;
    position: relative;
}

.dropdown-header-container {
    position: relative;
    margin-right: 10px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.dropdown-menu {
    border: none;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0,0,0,0.25);
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.25);
    font-size: 12px;
}

.dropdown-icons i {
    margin-right: 10px;
}

.dropdown-menu.media-list .dropdown-header {
    padding: 10px 15px!important;
    background: #f2f3f4;
    font-size: 10px;
    color: #585c61;
    margin: 0;
}