@import '../variables';

.field-wrap .wrapper {
    padding: 0 !important;
}

.form-group {
    .hint {
        font-size: 11px;
        padding-top: 5px;
    }

    .help-block {
        margin-bottom: 0;
    }
}

.form-horizontal.form-bordered .form-group > .control-label {
    padding: 22px 15px 15px 0;
    --deprecated: "WARNING: Use .form-border instead";
}

.input-xs {
    height: 22px;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

label.control-label {
    font-weight: 600;
    line-height: 1;
}

label.control-label > .form-control-required {
    font-weight: 600;
    color: $danger;
}

.form-control--error,
.form-control--error:focus {
    border: 1px solid $danger;
}

.form-control ~ .form-error {
    display: none;
    font-size: 12px;
    padding-top: 2px;
    color: $danger;
}

.form-horizontal.form-bordered .control-label {
     font-size: 14px;
     font-weight: 500;
    --deprecated: "WARNING: Use .form-border instead";
 }

@media (max-width: 767px) {

    .form-horizontal.form-bordered .form-group>div {
        padding: 5px 0 15px;
        --deprecated: "WARNING: Use .form-border instead";
    }

    .form-horizontal.form-bordered .form-group>.control-label {
        padding: 22px 15px 0 0;
        --deprecated: "WARNING: Use .form-border instead";
    }
}

label small {
    color: #707478;
    font-weight: 500;
}

.spaced-label small {
    color: #707478;
    font-weight: 500;
}

.spaced-label small strong {
    color: #242a30;
}

p.spaced-label {
    font-weight: bold;
    color: black;
    margin: 15px 0px 15px 0px;
}

.form-bordered {
    --deprecated: "WARNING: Use .form-border instead";
}

.form-horizontal.form-bordered .form-group {
    margin-bottom: -1px;
    --deprecated: "WARNING: Use .form-border instead";
}

@media (max-width: 979px) {
    .form-horizontal.form-bordered .form-group>.control-label {
        /*padding: 15px;*/
        line-height: inherit;
        --deprecated: "WARNING: Use .form-border instead";
    }
}

.form-horizontal.form-border{
    & .has-feedback .form-control-feedback{
        top:15px
    }

    & .form-group {
        border-bottom: 1px solid #eee;
        margin: 0;

        &:last-child {
            border-bottom: 0;
        }

        & .row {
            margin: 0 0 -1px 0;

            & > .control-label {
                font-size: 14px;
                font-weight: 500;
                padding: 22px 15px 15px 0;
            }

            & > div {
                @media (min-width: 767px) {
                    border-left: 1px solid #eee;
                    padding: 15px;
                }

                @media (max-width: 767px) {
                    padding: 5px 0 15px;
                }
            }

            & > .control-label {
                margin-right: -1px;

                @media (min-width: 767px) {
                    border-right: 1px solid #eee;
                    padding: 15px;
                }

                @media (max-width: 767px) {
                    padding: 22px 15px 0 0;
                }

                @media (max-width: 979px) {
                    line-height: inherit;
                }
            }
        }
    }
}

.input-daterange .input-group-addon {
    background: #e2e7eb !important;
}

.form-group-inline {
    display: inline-block;
    margin-right: 10px;
}

small.disclaimer {
    color: #707478;
    font-weight: 500;
    display: block;
    padding-top: 5px;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

input[type="text"].has-error,
textarea.has-error {
    background-color: #FFEDED;
    border-color: #FF6363;
}

.reset-password-container{
    p {
        line-height: 30px;
        margin-bottom:0;
    }
}

/*EL FORM*/
.el-form-item__label,
.el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0;
    margin-bottom: 5px;
    font-weight: 600;
    color: initial;
    font-size: 13px;
}

.el-form--label-right .el-form-item__label,
.el-form--label-left .el-form-item__label,
.el-form--inline .el-form-item__label {
    float: left;
    padding: 11px 12px 11px 0;
}

.el-form--label-left .el-form-item__label {
    text-align: left;
}

.el-form--label-right .el-form-item__label {
    text-align: right;
}

.el-form-item {
    margin-bottom: 15px;

    &.is-error {
        margin-bottom: 30px;
    }

    &.el-form-item--full-width {
        .el-form-item__label {
            width: 100%;
        }
    }
}

.el-form-item.is-required {
    // Hides default asterisk on required
    .el-form-item__label:before {
        content: none;
    }

    // Moves asterisk to the right
    .el-form-item__label:after {
        content: ' *';
        color: #ff5b57;
    }

    // Handle block to keep required asterisk with main label
    // but allow content underneath the label.
    &.el-form-item__label_block {
        .el-form-item__label:after {
            content: none !important;
        }

        .el-form-item__label_main:after {
            content: ' *';
            color: #ff5b57;
        }
    }
}

.top-ribbon__radio {
    display: inline-block;
    margin-top: 6px;

    .radio {
        margin-top: 0;
        margin-bottom: 0;
    }

    .el-radio__label {
        font-size: 12px;
    }

    .el-radio + .el-radio {
        margin-left: 10px;
    }
}
