.onboarding-item {
    width: 100%;
}

.primary-bg {
    background-color: #348fe2;
    color: #fff;
}

.onboarding-item__top  {
    width: 100%;
    height: 500px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.onboarding-item__header, .onboarding-item__subheader {
    color: #fff;
    margin: 0;
    font-weight: 300;
}

.onboarding-item__subheader {
    padding: 15px 50px;
    line-height: 1.6em;
    width: 100%;
}

.onboarding-item__top h1 {
    font-size: 45px;
    border-radius: 3px 3px 0 0;
}

.onboarding-item__header {
    padding-bottom: 15px;
}

.onboarding-item__title {
    text-align: left;
    border-radius: 3px 3px 0 0;
}

.onboarding-item__title h4 {
    margin: 0;
    font-size: 21px;
}

.onboarding-item__text {
    font-size: 16px;
    padding: 15px;
}

.onboarding-item__image--shadow {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
    border-radius: 3px;
    overflow: hidden;
}

.onboarding-item__image img {
    width: auto;
    height: 240px;
}

.footer-onboarding {
    position: relative;
}
.footer-onboarding a,
.footer-onboarding button {
    position: relative;
    z-index: 1;
}

.carousel-indicators--absolute {
    position: absolute;
}

.carousel-indicators--absolute.carousel-indicators {
    margin: 0 auto;
    display: table;
    bottom: 0;
    left: 0;
    top: 14px;
    z-index: 0;
    width: 100%;
    padding-left: 0;
    text-align: center;
    list-style: none;
    margin-top: 8px;
}

.text-white {
    color: #fff !important;
}

.carousel-indicators--absolute.carousel-indicators li {
    border: 1px solid #ccc;
}

.carousel-indicators--absolute.carousel-indicators .active {
    background-color: #ccc;
}

.carousel-inner {
    -webkit-transition: -webkit-transform .2s cubic-bezier(0.55,0,0,1);
    transition: -webkit-transform .2s cubic-bezier(0.55,0,0,1);
    transition: transform .2s cubic-bezier(0.55,0,0,1);
    transition: transform .2s cubic-bezier(0.55,0,0,1), -webkit-transform .2s cubic-bezier(0.55,0,0,1);
}

.modal-onboarding {
    width: 800px;
}

.onboarding-bar {
    background: #fff;
    border-radius: 3px;
    margin-bottom: 15px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px;
}

.onboarding-bar__center {
    overflow-x: auto;
    overflow-y: hidden;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.onboarding-bar__progress {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    width: 100%;
    -ms-flex-item-align: start;
        align-self: flex-start;
}

.onboarding-bar__steps-btn {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    -ms-flex-item-align: end;
        align-self: flex-end;
    white-space: nowrap;
    padding-left: 15px;
    padding-bottom: 2px;
}

.onboarding-bar__right {
    text-align: right;
}

.account-setup__except {
    margin-bottom: 15px;
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: 850px) {
    .onboarding-item {
        height: 85vh;
    }
    
    .onboarding-item__top  {
        height: 85vh;
    }
    
    .onboarding-item__top h1 {
        font-size: 40px;
        margin-bottom: 20px;
    }
    
    .modal-onboarding {
        width: 96vw;
    }
    
    .onboarding-item__image img {
        height: 200px;
    }
    
    h4 {
        font-size: 18px;
    }
}

@media screen and (max-width: 460px) {
    .onboarding-item {
        height: 87vh;
    }
    
    .onboarding-item__top  {
        height: 87vh;
    }
    
    .onboarding-item__top h1 {
        font-size: 34px;
    }

    .onboarding-item__image img {
        height: 170px;
    }
    
    h4 {
        font-size: 15px;
    }
}

@media screen and (max-width: 350px) {
    .onboarding-item {
        height: 85vh;
    }
    
    .onboarding-item__top  {
        height: 85vh;
        justify-content: normal;
    }
    
    .onboarding-item__top h1 {
        font-size: 30px;
        margin: 20px 0;
    }
    
    .onboarding-item__image img {
        height: 140px;
    }
    
    h4 {
        font-size: 14px;
    }
}
