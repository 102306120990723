
.navbar {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
}

.navbar-brand{
    padding:6px 20px;
}

.sidebar .nav > li.active > a,
.sidebar .nav > li.active > a:focus,
.sidebar .nav > li.active > a:hover,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.label.label-theme, .btn.btn-scroll-to-top {
    background: #3e454c;
    border-right: 1px solid #51585e;
}

.navbar.navbar-inverse .navbar-brand {
    background: transparent;
}

div#sidebar .nav li {
    border-left: 2px solid transparent;
}

div#sidebar .nav li.active .sub-menu li.active {
    border-color: transparent;
}

.sidebar .sub-menu>li>a {
    margin-left: -2px;
}

.sidebar-minify-btn {
    margin-bottom: 30px;
}

.navbar.navbar-inverse {
    background: #3e454c;
}

.navbar-brand img {
    height: 40px;
    padding: 3px;
}

.navbar-brand>img{
    display:inline-block;
}

li a.nav-btn-help {
    padding-right: 0;
}

.navbar-btn.btn-sm {
    margin: 11px 0px;
    padding: 5px 15px;
}

.navbar-right{
    margin-right:0;
}
.sidebar .nav>li>a img {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 12px;
}

.mobile-btn {
    margin: 0 20px;
}

.navbar-form .form-control {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.navbar-form .btn-search {
    color: #fff;
    top: 11px;
    border-radius: 0 3px 3px 0;
    font-size: 1.3em;
}

.navbar-form .form-control:focus {
    width: 200px;
    animation: none;
    -webkit-animation: none;
}

.navbar.navbar-inverse .navbar-form .form-control {
    color: #fff;
}

.navbar-brand {
    margin-right: 0;
    padding: 6px 15px;
    height: 54px;
    width: auto;
    font-weight: 100;
    font-size: 18px;
    line-height: 30px;
}

.navbar-form {
    margin: 9px 0;
}

.navbar.navbar-inverse .navbar-brand {
    border: 0 !important;
}

.navbar.navbar-inverse .navbar-form .form-control {
    background: rgba(255,255,255,0.16);
    border: 0;
    width: 300px;
    height: 36px;
}

.navbar-form .form-control::-webkit-input-placeholder { /* Chrome */
  color: rgba(255,255,255,0.7);
}

.navbar-form .form-control:-ms-input-placeholder { /* IE 10+ */
  color: rgba(255,255,255,0.7);
}

.navbar-form .form-control::-moz-placeholder { /* Firefox 19+ */
  color: rgba(255,255,255,0.7);
  opacity: 0.7;
}

.navbar-form .form-control:-moz-placeholder { /* Firefox 4 - 18 */
  color: rgba(255,255,255,0.7);
  opacity: 0.7;
}

li.mobile {
    display: none;
}

.navbar-form .btn {
    position: absolute;
    right: 19px;
    top: 10px;
    height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    background: 0 0;
    -webkit-border-radius: 0 30px 30px 0;
    -moz-border-radius: 0 30px 30px 0;
    border-radius: 0 30px 30px 0;
    color: rgba(255,255,255,0.7);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.navbar-form .btn:hover {
    color: #fff;
}

.expand-nav-search.open {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
}

.expand-nav-search .navbar-form {
    display: none;
}

.expand-nav-search.open .navbar-form {
    display: block;
}

.expand-nav-search.open .expand-nav-search-open {
    display: none;
}

.expand-nav-search .form-group {
    width: 100%;
    display: inline-block;
}

.navbar.navbar-inverse .expand-nav-search.open .navbar-form .form-control {
    width: 100% !important;
    background: #fff;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    color: #333;
}

.navbar.navbar-inverse .expand-nav-search.open .form-control::-webkit-input-placeholder {
    color: #333;
}

.navbar.navbar-inverse .expand-nav-search.open .form-control:-moz-placeholder {
    color: #333;
}

.navbar.navbar-inverse .expand-nav-search.open .form-control::-moz-placeholder {
    color: #333;
}

.navbar.navbar-inverse .expand-nav-search.open .form-control:-ms-input-placeholder {
    color: #333;
}

@media (max-width: 768px) {

    .navbar-inverse .navbar-header {
        background: #3e454c;
    }

    a.navbar-brand {
        border: none !important;
    }

    .navbar-default .navbar-toggle .icon-bar {
        background: #a8acb1;
    }

    .navbar-right {
        display: none;
    }

    li.mobile {
        display: block;
    }

    .navbar-form .form-control, .navbar-form .form-control:focus {
        width: 180px!important;
    }

    .navbar-form .btn-search {
        color: #fff;
        top: 15px;
        border-radius: 0 3px 3px 0;
        font-size: 1.2em;
        right: 17px;
    }

    .navbar-btn.btn-sm {
        margin: 8px 0px;
    }

    .navbar-form {
        padding: 8px 20px;
    }

    .navbar-form .form-control {
        border: 0;
        background: rgba(255,255,255,0.16);
        color: #a8acb1;
    }

    .sidebar .nav li:first-child {
        margin-top: 16px;
    }
}

.sidebar .nav>li.expand>a,
.sidebar .nav>li>a:focus,
.sidebar .nav>li>a:hover {
    background: #232a2f;
    color: #eee;
}

/*Start New Sidebar UI*/

.sidebar-push {
    right: 0;
    left: -230px;
    position: fixed;
    transition: left 0.3s ease;
    padding-top: 0;
}

.page-sidebar-push .top-ribbon,
.navbar-push {
    width: 100%;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
}

.page-sidebar-push {
    width: 100%;
    left: 0;
    right: 0;
}

body.sidebar-open .page-sidebar-push .top-ribbon,
body.sidebar-open .navbar-push,
body.sidebar-open .page-sidebar-push {
    width: calc(100% - 220px);
    right: 0;
    left: 220px;
}

body.sidebar-open .sidebar-push + .page-sidebar-push {
    padding-top: 54px;
    margin-right: 0;
    left: 220px;
    position: relative;
    transition: all 0.3s ease;
}

body.sidebar-open .sidebar-push {
    left: 0;
}

body.sidebar-open .navbar-toggle-open {
    display: none;
}

.navbar.navbar-inverse .navbar-brand {
    border-right: 0;
}

.navbar-toggle {
    display: block;
    float: left;
}

.sidebar-push-close .navbar-toggle-close .icon-bar,
.navbar-default .navbar-toggle .icon-bar {
    background: #fff;
}

.sidebar-push-close {
    text-align: right;
}

.sidebar-push-close .navbar-toggle-close {
    float: none;
    display: inline-block;
    margin: 0;
    padding: 0 10px;
}

.sidebar-close-btn-group {
    padding: 13px 10px 5px 0px;
    border-right: 1px solid rgba(255,255,255,0.16);
    background: #3e454c;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    position: relative;
    z-index: 3;
}

.sidebar-close-btn-group i {
    line-height: 2;
    color: #fff;
    font-size: 15px;
    padding-bottom: 6px;
    vertical-align: middle;
}

.content {
    margin-left: 0;
}

.sidebar .nav > li > a {
    padding: 10px 20px;
    line-height: 20px;
    color: #cfd8dc;
}

.sidebar .nav > li > a i {
    color: #546E7A;
    font-size: 16px;
}

.sidebar [class^="fa "] {
    opacity: 0.6;
}

.sidebar-mobile-profile,
.sidebar-mobile-app-switcher{
    border-bottom: 1px solid rgba(255,255,255,.16);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.app-switcher {
    display: none;
}

@media screen and (max-width: 1081px) {

    .navbar.navbar-inverse .navbar-form .form-control {
        width: 185px;
    }
}

@media screen and (max-width: 768px) {

    .navbar-nav {
        display: none;
    }

    .sidebar-push {
        position: fixed;
    }

    .navbar.navbar-inverse .navbar-brand {
        float: right;
    }

    .body.sidebar-open .page-sidebar-push .top-ribbon,
    body.sidebar-open .navbar-push,
    body.sidebar-open .page-sidebar-push {
        width: 100%;
    }

    body.sidebar-open .sidebar-push + .page-sidebar-push {
        position: static;
        overflow: hidden;
    }

    .sidebar-open {
        overflow: hidden;
    }

    .app-switcher{
        font-size: 25px;
        display: inline-block;
        list-style: none;
        height: 54px;
        line-height: 54px;
        float: left;
    }

    .app-dropdown{
        left: -150px;
    }
}

@media screen and (max-width: 415px) {

    .nav-sidebar-push {
        padding-bottom: 125px;
    }
}
