.pp-table__wrapper {
  overflow: auto;
  position: relative;
}
.pp-table {
  width: calc(100% - 20px);
  position: relative;
  margin: 20px auto;
  background-color: rgb(255, 255, 255);
  font-size: 12px;
  color: rgb(40, 50, 60);
  text-align: left;
}
.pp-table__caption {
  font-size: 16px;
  color: rgb(20, 20, 20);
  background-color: rgb(235, 245, 255);
  padding-left: 10px;
}
.pp-table__row-label {
  width: 150px;
  padding: 10px 20px 10px 10px;
  font-weight: normal;
  white-space: nowrap;
}
.pp-table__header, .pp-table__data {
  min-width: 100px;
  padding: 10px 20px;
}
.pp-table__head {
  background-color: rgb(255, 255, 255);
}
.pp-table__row--gray {
  background-color: rgb(245, 245, 245);
}
.pp-table__row--white {
  background-color: rgb(255, 255, 255);
}
.pp-message {
  text-align: center;
  margin: 20px;
}