.media-card {
    background: #f0f3f4;
    margin-bottom: 25px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
}

img.media-card-image {
    width: auto;
    max-height: 200px;
    max-width: 100%;
    margin: 0 auto;
    display: table;
}

img.media-card-image-left {
    width: auto;
    max-height: 200px;
    max-width: 100%;
    display: table;
}

.media-card__header{
    height: 50px;
    padding: 10px 15px;
    background-color: #fff;
}

.media-card__title{
    margin: 0;
    line-height: 30px;
}

.media-card__image-container {
    padding: 15px;
    height: 232px;
    background: #f0f3f4;
    border-radius: 3px 3px 0 0;
}

.media-card__tags, .media-card__tag {
    padding-bottom: 5px;
}

.media-card__info {
    padding: 10px 15px;
    height: 80px;
}

.media-card-footer {
    margin-top: 5px;
}

.media-card-icon {
    z-index: 0;

    i {
        font-size: 50px;
    }
}
