div.hopscotch-bubble .hopscotch-nav-button.next {
    border-color: #348fe2;
    color: #fff;
    margin: 0 0 0 10px;
     text-shadow: none;
    background-color: #348fe2;
    filter: inherit;
    background-image: none;
    background-image: none;
    background-image: none;
    background-image: none;
}

div.hopscotch-bubble .hopscotch-nav-button.next:hover {
    border-color: #2a72b5;
    color: #fff;
    margin: 0 0 0 10px;
     text-shadow: none;
    background-color: #2a72b5;
    filter: inherit;
    background-image: none;
    background-image: none;
    background-image: none;
    background-image: none;
}

div.hopscotch-bubble .hopscotch-nav-button.prev {
    border-color: #c8c8c8;
    color: #fff;
    margin: 0 0 0 10px;
     text-shadow: none;
    background-color: #c8c8c8;
    filter: inherit;
    background-image: none;
    background-image: none;
    background-image: none;
    background-image: none;
}

div.hopscotch-bubble .hopscotch-nav-button.prev:hover {
    border-color: #a3a3a3;
    color: #fff;
    margin: 0 0 0 10px;
     text-shadow: none;
    background-color: #a3a3a3;
    filter: inherit;
    background-image: none;
    background-image: none;
    background-image: none;
    background-image: none;
}

div.hopscotch-bubble .hopscotch-bubble-number {
    background: transparent;
    color: #348fe2;
    border: 2px solid #348fe2;
    display: block;
    float: left;
    font-size: 17px;
    font-weight: bold;
    line-height: 31px;
    padding: 0;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

div.hopscotch-bubble .hopscotch-bubble-close {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    text-indent: initial;
}

.hopscotch-bubble-number {
    display: none !important;
}

.hopscotch-bubble-content {
    margin: 0 !important;
}