.fv-container {
    padding  : 32px;
    position : relative;
    top      : 89px;
}


// Card Header
.fv-header-section {
    padding : 25px 40px 10px;
}

.fv-header-section__title {
    display     : inline-block;
    padding     : 12px 0;
    line-height : normal;
    font-size   : 24px;
    font-weight : 400;
    color       : #363F49;
    margin      : 0;
}

.fv-header-section button {
    margin       : 12px 8px;
    border-color : #348fe2 !important;
}

.fv-card-wrapper {
    margin-bottom : 10px;
    padding       : 0 10px 10px !important;
    overflow      : hidden;
    min-width     : 720px;
}

.fv-card-header {
    padding          : 0 20px;
    height           : 75px;
    border-radius    : 5px;
    background-color : #fff;
    box-shadow       : 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    position         : relative;
    z-index          : 3;
    transition       : background-color .3s;
}

.st-card-header {
    padding          : 0 20px;
    height           : 75px;
    background-color : #E9FDF4;
    box-shadow       : 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    position         : relative;
    z-index          : 3;
}

.fv-card-header-content {
    width       : 100%;
    overflow    : hidden;
    white-space : nowrap;
}

.fv-card-header-tr td {
    display : table-cell;
    padding : 0 8px;
}

.fv-vertical-separator {
    background : rgba(128, 128, 128, .25);
    width      : 1px;
    height     : 40%;
    margin     : 0 8px;
}

.fv-horizontal-separator {
    background : rgba(0, 0, 0, 0.1);
    width      : 100%;
    height     : 1px;
    margin     : 20px 0;
}

.fv-card-handle {
    padding      : 8px 12px 8px 12px;
    margin-right : 8px;
    font-size    : 16px;
    white-space  : nowrap;
}

.fv-draggable-ghost {
    opacity    : 0.25;
    background : #C8EBFB;
}

.fv-card-handle:hover {
    cursor : -webkit-grab;
    cursor : grab;
}

.fv-card-handle:active {
    cursor : -webkit-grabbing;
    cursor : grabbing;
}

.fv-fade-enter-active,
.fv-fade-leave-active {
    transition : opacity .5s ease-in-out;
}

.fv-fade-enter,
.fv-fade-leave-to {
    opacity : 0;
}

.fv-fade-enter-to,
.fv-fade-leave {
    opacity : 1;
}


// Slide Down Panel
.fv-card-slide-panel {
    border-bottom-left-radius  : 5px;
    border-bottom-right-radius : 5px;
    background-color           : #fff;
    box-shadow                 : 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width                      : auto !important;
    overflow-x                 : scroll;
    scrollbar-gutter           : stable;
}

.fv-slide-enter-active,
.fv-slide-leave-active {
    transition : height .25s ease-in-out;
    overflow   : hidden;
}

.fv-slide-enter,
.fv-slide-leave-to {
    height : 0;
}

.fv-slide-enter-to,
.fv-slide-leave {
    height : auto;
}

.fv-rules-panel,
.fv-sort-order-panel,
.fv-column-order-panel,
.fv-team-selection-panel,
.fv-outcomes-panel {
    padding : 40px;
}

.fv-outcomes-panel,
.fv-rules-panel,
.fv-team-selection-panel {
    border-top : 1px solid rgba(128, 128, 128, 0.25);
}

//Rule Type Container
.fv-rule-type-container {
    cursor     : pointer;
    transition : max-height 0.3s, padding-top 0.3s, padding-bottom 0.3s;
    overflow   : hidden;
}

.fv-rule-type-container-hidden,
.fv-outcome-collapse-item-hidden {
    max-height     : 0px;
    padding-top    : 0px;
    padding-bottom : 0px;
}

.fv-rule-type-container-visible {
    max-height     : 1000px;
    padding-top    : 5px;
    padding-bottom : 5px;
}

.fv-rule-type-container:hover {
    color : #337ab7;
}

.fv-rule-type-category-container,
.fv-collapse-header-container {
    background-color : #F8F8F8;
    transition       : max-height 0.3s;
    overflow         : hidden;
}

.fv-rule-type-category-container .el-collapse-item__header {
    background-color : #F8F8F8;
    color            : #626262;
    font-weight      : bold;
}

.fv-rule-type-category-container-visible {
    max-height    : 1000px;
    border-top    : 1px solid #E5E5E5;
    border-bottom : 1px solid #E5E5E5;
}

.fv-rule-type-category-container-hidden {
    max-height : 0px;
}

/* hide the arrow icon from the collapsables elements on the Focused View rule list */
.fv-rule-type-category-container .el-collapse-item__header__arrow.el-icon-arrow-right,
.fv-collapse-header-container .el-collapse-item__header__arrow.el-icon-arrow-right {
    display : none;
}

.fv-unpublished-label {
    margin : 0 10px 0 15px;
}

.fv-unpublished-label span {
    color : #242a30;
}

.fv-side-panel-wrapper {
    width      : 100%;
    overflow   : auto;
    max-height : calc(100% - 65px);
    position   : absolute;
    top        : 0;
    left       : 0;
    padding    : 0 0 150px 0;
    margin     : 65px 0 0;
}

.fv-side-panel-item-wrapper {
    padding : 20px 30px;
}

.fv-side-panel-wrapper p {
    margin-bottom : 5px;
}

.fv-bottom-border {
    border-bottom : 1px solid rgba(128, 128, 128, 0.25);
}

.fv-side-panel-form-wrapper {
    background : #fafafa;
    padding    : 30px;
}

.fv-filter-panel {
    padding : 0 20px 150px 20px;
}

.fv-share-or-assign-leads {
    padding : 30px;
}

.fv-card-deleted {
    border           : 1px solid #D20000;
    background-color : #FEF7F7;
}

.fv-card-bg-color {
    background-color : #E6F1FB;
}

//All/Edit Outcomes
.fv-create-outcome-panel,
.fv-create-followup-task-panel {
    padding          : 10px 15px;
    background-color : #EDF5FC;
    font-size        : 13px;
    color            : rgb(31, 43, 61);
    line-height      : 1.7;
}

.fv-outcomes-title {
    padding     : 0 25px;
    height      : 100%;
    font-size   : 14px;
    font-weight : 600;
}

.fv-outcomes-title-name {
    max-width     : 310px;
    overflow      : hidden;
    line-height   : 1.2em;
    white-space   : nowrap;
    text-overflow : ellipsis;
}

.fv-collapse-header-container .el-collapse-item__header {
    border           : none;
    background-color : #FFF;
    color            : #626262;
    font-weight      : bold;
}

.fv-collapse-header-container .el-collapse-item__wrap {
    background-color : #fafafa;
}

.fv-rule-type-container-visible {
    max-height : 1000px;
}

.fv-delete-button {
    color        : #ff5b57 !important;
    border-color : #ff5b57 !important;
}

.fv-cancel-button {
    color        : #348fe2 !important;
    border-color : #348fe2 !important;
}

.fv-edit-outcome-alert {
    border           : 1px solid #1A73E8;
    border-radius    : 6px;
    background-color : #FFF;
    width            : 100%;
    padding          : 7px;
}

.fv-select-create-outcome-text {
    color      : #348fe2;
    padding    : 10px;
    text-align : center;
    cursor     : -webkit-pointer;
    cursor     : pointer;
}

.fv-card-title {
    font-size   : 16px;
    font-weight : bold;
}

.fv-el-input--title {
    .el-input__inner {
        @extend .fv-card-title;
    }
}

.fv-title-readonly {
    @extend .fv-card-title;
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.fv-card-description {
    font-size : 12px;
}

.fv-el-input--description {
    .el-input__inner {
        @extend .fv-card-description;
    }
}

.fv-description-readonly {
    @extend .fv-card-description;
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.fv-search-filter-no-results-found {
    padding   : 10px;
    font-size : 16px;
}

.fv-no-bottom-border {
    border-bottom : none;
}


// LO View
.fv-contact-row-contents {
    height        : 75px;
    padding       : 0 20px 0 8px;
    border-bottom : 1px solid rgba(128, 128, 128, 0.25);
    position      : relative;
    min-width     : 915px;
}

.fv-contact-row-tr td {
    display   : table-cell;
    padding   : 0 8px;
    min-width : 170px;
}

.fv-contact-info {
    max-width   : 1000px;
    margin-left : 20px;
}

.fv-contact-info>* {
    width         : 120px;
    padding-right : 5px;
}

.fv-contact-pagination {
    height   : 50px;
    position : sticky;
    left     : 0;
    right    : 0;
}

.fv-contact-slide-down {
    border-bottom : 1px solid rgba(128, 128, 128, 0.25);
}

.fv-contact-detail {
    padding    : 30px 0;
    min-width  : 920px;
    position   : relative;
    background : #FAFAFA;
}

.fv-contact-detail-column {
    padding   : 0 30px;
    position  : relative;
    min-width : 200px;
    max-width : 400px;
    word-wrap : break-word;
    overflow  : hidden;
}

.fv-contact-detail-column p {
    margin-bottom : 5px;
}

.fv-contact-detail-buttons {
    width   : 175px;
    display : block;
}

.fv-mobile-actions,
.fv-mobile-notes-follow-up {
    display : none;
}

.fv-custom-view-placeholder {
    padding          : 25px;
    border-radius    : 5px;
    background-color : #fff;
    box-shadow       : 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    width            : 100%;
    text-align       : center;
}

@media only screen and (max-width: 1350px) {
    .fv-mobile-actions {
        display : inline;
    }

    .fv-desktop-actions {
        display : none;
    }
}

@media only screen and (max-width: 1350px) {
    .fv-mobile-notes-follow-up {
        display : inline;
    }

    .fv-desktop-notes-follow-up {
        display : none;
    }
}