
.lead-transfer-wizard__header {
    padding: 15px 0 0 0;
}

.lead-transfer-wizard .v-stepper {
    padding-left: 15px;
    padding-right: 15px;
}

.lead-transfer-confirmation__body {
    padding: 30px 20px;
}

.transfer-owner-review-step > ul {
    padding-left: 15px;
    margin-bottom: 25px;
}

.transfer-owner-review-step .still-transferring-text {
    margin-top: 15px;
}