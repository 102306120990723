/* video modal
================================================== */

.modal-video-content {
    width: 90%;
    position: absolute;
    max-width: 1170px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 300;
    text-align: center;
}

.modal-video-header {
    padding: 20px 0px;
    overflow: hidden;
}

.embed-responsive.no-value {
    display: none;
}

.btn-full-close {
    float: right;
}

@media only screen and (min-width: 768px) {

    .modal-video-content {
        width: 60%
    }
}

/* video alert panel
================================================== */

.video-item {
    border-radius: 3px;
    margin-bottom: 30px;
}

.video-item--white {
    background: #fff;
}

.video-item__thumbnail {
    position: relative;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
}

.video-item__image {
    width: 100%;
}

.video-item__title {
    text-align: center;
    margin: 0;
    padding: 15px 10px;
    min-height: 70px;
    color: #333;
}

.video-item__overlay {
    background: rgba(0,0,0,.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-transition: background 150ms ease-out;
    -o-transition: background 150ms ease-out;
    transition: background 150ms ease-out;
}

.video-item__overlay:hover {
    background: rgba(0,0,0,.1);
}

.video-item__overlay:hover .fa-play {
    color: #348fe2;
}

.video-item__overlay .fa-play {
    font-size: 30px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    font-weight: 300;
    text-align: center;
    -webkit-transition: background 150ms ease-out;
    -o-transition: background 150ms ease-out;
    transition: background 150ms ease-out;
}

.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

.video-responsive iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-responsive embed {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}