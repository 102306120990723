.price-description {
    font-size: 14px;
    font-weight: 500;
}

.price-total-label {
    border-bottom: 1px solid #d6d6d6;
    font-size: 15px;
    margin-top: 15px;
}

.order-form-header {
    padding-top: 25px;
    font-weight: 600;
    padding-bottom: 15px;
}
