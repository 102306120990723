$comarketing-dialog-height: 120px;
$comarketing-dialog-height-medium: 140px;
$comarketing-dialog-scroll-size: 335px;
$comarketing-dialog-medium-scroll-size: 375px;

.comarketing-invite-top-ribbon {
    height: 95px;
}

.comarketing-invite-steps {
    padding-top: 15px;
    padding-left: 15%;
    padding-right: 15%;
}

.comarketing-dialog-with-ribbon {
    height: calc(100vh - #{$comarketing-dialog-scroll-size});
    overflow: auto;
}

.comarketing-dialog > .el-dialog{
    height: calc(100% - #{$comarketing-dialog-height});
    overflow: hidden;
}

.comarketing-dialog > .el-dialog > .el-dialog__body{
    height: calc(100% - #{$comarketing-dialog-height});
    overflow: hidden;
}

@mixin media-max-md {
    .comarketing-dialog > .el-dialog{
        height: calc(100% - #{$comarketing-dialog-height-medium});
    }

    .comarketing-dialog > .el-dialog > .el-dialog__body{
        height: calc(100% - #{$comarketing-dialog-height-medium});
    }

    .comarketing-dialog-with-ribbon {
        height: calc(100vh - #{$comarketing-dialog-medium-scroll-size});
        overflow: auto;
    }
}
