.time-picker {
    font-size: inherit !important;
    width: 100% !important;
    font-family: inherit !important;

    input.display-time {
        display: block;
        width: 100% !important;
        height: 34px !important;
        padding: 6px 12px !important;
        font-size: 12px !important;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccd0d4 !important;
        border-radius: 3px;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

        &:focus {
            border-color: $focus-border-color;
        }
    }

    .dropdown {
        border-radius: 3px;
        width: auto !important;

        ul li:not(.hint):hover {
            background: $dropdown-hover-color !important;
            color: $text-black !important;
        }

        .select-list {
            width: 15em !important;
        }
    }

    .clear-btn {
        font-size: 18px !important;
        top: 2px !important;
        right: 5px !important;
    }

    .dropdown ul li.active,
    .dropdown ul li.active:hover {
        background: $primary !important;
    }

}