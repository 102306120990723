.zillow-connect-image {
    background-image: url("./images/zillow-connect-form.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 400px;
    width: 100%;
    margin: 0 auto;
}

@media screen and (min-width: 768px){
    .zillow-connect-image{
        height: 800px;
    }
}

@media screen and (min-width: 1200px){
    .zillow-connect-image{
        height: 1000px;
    }
}

.zillow-connect-image{
  background-image: url("./images/zillow-connect-form.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 400px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px){
  .zillow-connect-image{
    height: 800px;
  }
}

@media screen and (min-width: 1200px){
  .zillow-connect-image{
    height: 1000px;
  }
}
