@import '../variables.scss';

$rule_gutter: 10px;
$rule_gutter_flex: 1 1 calc(33% - #{2 * $rule_gutter});
$line_separator_color: rgba(150, 150, 150, 0.5);
$line_separator-width: 1px;

$rule_actions_btn_width: 50px;

.vue-query-builder-styled {
  background-color: $white;
  border: $line_separator-width solid $line_separator_color;
}

.vue-query-builder-styled .vqb-rule {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
}

.vue-query-builder-styled .vqb-rule label {
  margin-right: 10px;
  margin-bottom: 10px;
}

.vue-query-builder-styled .vqb-group-heading {
  margin-bottom: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
}

.vqb-children {
  margin-bottom: 0;
}

.vue-query-builder-styled .close {
  opacity: 1;
  color: rgb(150,150,150);
}

.vqb-rule-input-row .vqb-inline {
  display: flex;
  flex-wrap: wrap;
  margin: 0 #{-1 * $rule_gutter} -15px #{-1 * $rule_gutter};
}

.vqb-rule-input .vqb-inline {
  flex: $rule_gutter_flex;
  margin: 0 $rule_gutter;
}

.vqb-strike-empty {
  min-height: 2px;
  border-bottom: 1px solid;
  color: $line_separator_color;
  width: 100%;
  vertical-align: middle;
}

.vqb-strike {
  overflow: hidden;
  text-align: center;
  font-weight: 600;
  color: $line_separator_color;
  min-height: 2px;
}

.vqb-strike:before, .vqb-strike:after {
  content: "";
  display: inline-block;
  width: 50%;
  margin: 0 .5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}

.vqb-rule-view {
  display: flex;
  flex-direction: row;
}

.vqb-rule-view-actions {
  display: flex;
  flex-direction: column;
  width: #{$rule_actions_btn_width};
}

.vqb-description {
  overflow-wrap: break-word;
  max-width: calc(100% - #{$rule_actions_btn_width});
  margin-right: auto;

}

.vqb-strike:after {
  margin: 0 -55% 0 .5em;
}

.vqb-strike > span {
  display: inline-block;
  vertical-align: middle;
}

.vqb-operator {
  width: 100%;
}

.vqb-operator-label {
  margin: 15px 0;
  color: #242a30;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .vue-query-builder-styled .vqb-rule.form-inline .form-group {
    display: block;
  }
}