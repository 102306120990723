/* Media Creator Page
================================================== */

.media-editor {
    display:inline-block;
    background: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(128,128,128,.1)inset;
    box-shadow: 0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(128,128,128,.1)inset;
}

.media-editor .editable {
    cursor:text;
}

.media-editor .editable-image {
    cursor:pointer;
}

.media-editor .mce-edit-focus,
.media-editor .editable:hover,
.media-editor .editable-image:hover,
.media-editor .preset:hover {
    -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(205, 206, 249, 0.6)inset;
    box-shadow: 0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(205, 206, 249, 0.6)inset;
}

.media-editor .mce-content-body {
    position:relative;
}

.editable-image-container {
    position: relative;
    overflow: hidden;
}

.editable-image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 0;
    top: 0;
    border: 1px dashed #d6d6d6;
}

.editable-image-text {
    font-size: 12px;
    width: 90%;
}

.media-editor .editable-image:hover .editable-image-overlay {
    opacity: 1;
}

.editable-image {
    position: relative;
}

.media-editor .disabled {
    cursor:default;
}

.media-markup__container {
    max-width: 800px;
    margin: 0 auto;
    display: block
}

.mm__cw {
    display: none;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    border-radius: 3px;
    margin: 0 25px;
}

.mm__img {
    max-width: 800px;
}

.btn-split {
    width: 50%;
    float: left;
}

.btn-split:first-child {
    padding-right: 5px;
}

.btn-split:last-child {
    padding-left: 5px;
}

.split-group {
    overflow: hidden;
}

.mm__box {
    padding: 25px;
}

.modal-body--tabs {
    padding: 0;
}

.media-editor {
    display: inline-block;
    background: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(128,128,128,.1)inset;
    box-shadow: 0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(128,128,128,.1)inset;
}

.js-media-zoom-container {
    position: absolute;
    transition-duration: 150ms;
    -webkit-transform-origin: top left;
    transform-origin: top left;
}

img.layout-thumbnail-image {
    height: 70px;
    display: block;
    margin: 0;
    padding: 0 0 6px 0;
}