.input-group--media-filter {
    padding-right: 0;
    width: 100%;
}

.list-group--folders,
.list-group--tags {
    display: none;
}

.media-filter-top--desktop {
    display: none;
}

.media--flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.media--flex .media-right {
    flex: 0 0 auto;
    width: 17%;
    text-align: right;
}

@media screen and (min-width: $iphone-plus) {
    .media--flex .media-right {
        width: 22%;
    }
}

@include media-lg {
    .mobile-media-filter-modal-btn {
        display: none;
    }

    .list-group--folders,
    .list-group--tags {
        display: block;
    }

    .media-filter-top--desktop {
        display: block;
    }
}

@media only screen and (max-width: 900px) {
  .media-view-list-buttons {
    display: none;
  }

  .truncate-fit {
    width: 410px;
  }
}

@media only screen and (max-width: 767px) {
  .media-view-list-buttons {
    display: none;
  }

  .truncate-fit {
    width: 410px;
  }
}

@media only screen and (max-width: 670px) {
  .media-view-list-buttons {
    display: none;
  }

  .truncate-fit {
    width: 368px;
  }
}

@media only screen and (max-width: 420px) {
  .media-view-list-buttons {
    display: none;
  }

  .truncate-fit {
    width: 268px;
  }
}
