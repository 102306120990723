.di-wizard-footer {
    background: white;
    border-top: 1px solid rgb(217, 217, 217);
    flex: 0 0 75px;

    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.di-wizard-stepper {
    margin-left: 5%;
    height: 300px;
    min-width: 300px;
}

.di-wizard-panel-body {
    padding: 10px;
    height: 100%;
    width: 75%;
}

.di-wizard-body {
    overflow: auto;
    background: rgb(239, 239, 239);
    margin-bottom: 15px;
    padding: 50px 50px 0px 50px;
    flex: 1 1 auto;

    display: flex;
    flex-flow: row;
    height: 100%;
}

.di-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}
