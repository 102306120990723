.am-form-title {
    font-size: 1.1em;
    color: #303133;
    margin: 10px 0 20px 0;
    font-weight: 600;
    line-height: 24px;
}

.am-tooltip {
    color: #5786c7;
    font-size: 1.1em;
}

.am-delete-confirm {
    font-size: 16px;
    width: auto;
}

.am-delete-confirm .el-message-box__content {
    padding: 20px 20px;
}

.am-delete-confirm .el-message-box__message p {
    font-size: 1em;
    line-height: 24px;
}

.am-delete-confirm .el-message-box__btns {
    border-top: solid 1px #eee;
}

.am-header-section {
    position: relative;
}

.am-header-section h1 {
    font-size: 3em;
    padding: 0 10px;
}

.am-details-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

.detail {
    flex-grow: 1;
    color: $text-black;
    margin: 5px;
}

.detail__title {
    font-size: 1em;
    font-weight: 700;
    margin: 0 0 5px 0;
}

.am-panel .panel-heading {
    border-bottom: 1px solid #eee !important;
}

.am-panel__body {
    padding: 20px;
}

.am-dropdown-link {
    font-weight: 600 !important;
}

.open>.am-dropdown {
    margin-top: 10px;
}

.am-button-wrap {
    padding: 10px 0 !important;
}

.am-form-item .am-form-item--lead-update {
    margin: 0;
}

.am-form-item--info-text {
    font-size: 12px;
    line-height: 24px;
}