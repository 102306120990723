.product-info-box {
  @extend .lead-info-box;
}

.product-field-label {
  @extend .lead-stat-label;
  width: 340px;
}

.product-field-value {
  @extend .lead-stat-value;
}

.product-field-row {
  @extend .loan-stat;
}
