.alert-bottom {
    position: fixed;
    bottom: 5px;
    left: 20px;
    z-index: 2000;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
}

.alert-unfinished {
    overflow: auto;
    line-height: 2.5;
}

.alert.alert-info {
    background: #bee2ef;
    color: #31708f;
}