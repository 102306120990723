@import "../variables";
@import "../vars/journeys/operator-colors-v1";

$form-header-height: 55px;

$operator-form-footer-height: 71px;
$operator-form-header-height: 65px;
$operator-form-body-offset: $operator-form-header-height + $top-ribbon-height + $global-header-height; //old
$operator-form-body-offset-app: $operator-form-header-height + $form-header-height + $global-header-height; //new
$side-menu-width: 80px;

.journeys-page {
    position: relative;
    height: calc(100vh - #{$global-header-height});
    top: 0;

    .journeys-page__content {
        position: relative;
        height: calc(100vh - #{$global-header-height} - #{$form-header-height});
        top: $form-header-height;
        overflow: hidden;

        .journey-flowchart {
            position: absolute;
            top: 0;
            bottom: 0;
            left: $side-menu-width;
            right: 0;
            height: auto;
            width: auto;
        }
    }

    .header-section__inside {
        display: flex;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;

        .header-section__title {
            padding-left: 0;
        }
    }

    .operator-form {
        .side-panel__body {
            height: calc(100vh - #{$operator-form-body-offset-app});
        }

        &.has_footer {
            .side-panel__body {
                height: calc(100vh - #{$operator-form-body-offset-app + $operator-form-footer-height});
            }
        }

        .side-panel__footer {
            height: $operator-form-footer-height;
        }
    }
}

.operator-form {
    position: absolute;
    width: 300px;
    padding: 0;
    z-index: 20;
    -webkit-box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.22);
    -moz-box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.22);
    box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.22);
    background: #fff;
    right: -300px;
    outline: none;
    top: 0px;

    .side-panel__body {
        height: calc(100vh - #{$operator-form-body-offset});
    }

    &.has_footer {
        .side-panel__body {
            height: calc(100vh - #{$operator-form-body-offset + $operator-form-footer-height});
        }
    }
}

.journey-review-panel {
    position: absolute;
    display:none;
    background-color: #fff;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    z-index: 10;
    overflow-y:scroll;
}

.js-save-operator-btn {
    min-width: 125px;
}

.journey-flowchart {
    width: 100%;
    height:calc(100vh - 98px);
    overflow: hidden;

    .journey-container {
        width: 30000px;
        height: 30000px;
        background-size: 20px 20px;
        background-image:
            linear-gradient(to right, #f9f9f9 1px, transparent 1px),
            linear-gradient(to bottom, #f9f9f9 1px, transparent 1px);
    }
}

.journey-leads-table {
    border:1px #ccc solid;
    bottom: 0;
    height: 400px;
    left:0;
    position: absolute;
    right:0;
}

.operator-box {
    margin:7px;
    padding: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    border: 1px #f9f9f9 solid;
    border-top-width: 5px;
    border-radius: 3px;
    cursor: move;
    color: $black;

    &.op-global-trigger,
    &.op-trigger {
        border-top-color: $trigger-color;
    }

    &.op-action {
        border-top-color: $action-color;
    }

    &.op-action-end {
        border-top-color: $action-end-color;
    }

    &.op-condition {
        border-top-color: $condition-color;
    }

    &.op-event {
        border-top-color: $event-color;
    }
}

.sidebar-form h3,
.operator-form h3 {
    border-bottom:1px #ececec solid;
    padding:3px 0 3px 5px;
    margin-top:0;
}

.sidebar-form {
    height: calc(100vh - 98px);
    position: absolute;
    z-index: 1;
}

.collapsing {
    -webkit-transition-property: visibility;
    -o-transition-property: visibility;
    transition-property: visibility;
    height:auto;
}

.operator-form .recurrence-limit {
    display: none;
    &.active {
        display: block;
    }
}
.journey-side-menu {
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $side-menu-width;
    z-index: 21;
    padding-top: 10px;

    .journey-side-menu-item {
        height: 100%;
        line-height: 2em;
        padding: 20px;
        width: 100%;
    }
}

.journey-side-menu-label {
    padding-top: 15px;
}
.journey-side-panel-wrapper {
    &.ltr {
        left: $side-menu-width;
    }

    .side-panel__body {
        height: calc(100vh - 180px);
        overflow: auto;
    }
}
