.tab-content.border {
    border: 1px solid #ccd0d4;
    overflow: auto;
}

.tab-content-no-panel {
    padding: 0;
    background: transparent;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 3px 3px 0 0;
    padding: 15px;
}

.nav-tabs {
    background: #c1ccd1;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}

.nav-tabs.tab-inverse {
    background: #fff;
    border-bottom: 1px solid #eee;
    margin-bottom: 1px;
}

.nav.nav-tabs.tab-inverse>li>a {
    color: inherit;
}

.nav.nav-tabs.tab-inverse>li>a:focus,
.nav.nav-tabs.tab-inverse>li>a:hover {
    color: #3e454c;
    background: #fff;
}

.nav.nav-tabs.tab-inverse>li.active>a,
.nav.nav-tabs.tab-inverse>li.active>a:focus,
.nav.nav-tabs.tab-inverse>li.active>a:hover {
    color: #3e454c;
    background: #fff;
}

.nav.nav-tabs > li.active > a:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background: #348fe2;
    -webkit-animation: border-expand .2s cubic-bezier(.4,0,.4,1).01s alternate forwards;
    animation: border-expand .2s cubic-bezier(.4,0,.4,1).01s alternate forwards;
    transition: all 1s cubic-bezier(.4,0,1,1);
}

.el-tabs--no-border {
    .el-tabs__header {
        border-bottom: 0;
    }
}

.el-tabs__header {
    background: #fff;
}

.no-header-margin {
    .el-tabs__header {
        margin-bottom: 0;
    }
}

.el_tabs--dialog-scroll {
    .el-tabs__content {
        max-height: calc(100vh - 341px);
        overflow: auto;
    }
}

.el-tabs--pane-padding {
    .el-tabs__content {
        padding: 0 15px 15px 15px;
    }
}

.header-section-body--tabs {
    padding-top: 115px;
    padding-left: 25px;
    padding-right: 25px;
}


