.vue-wizard-contain {
    margin: -71px -25px -25px -25px;
    padding-bottom: 0;

    .wizard-header {
        padding: 0;
    }

    .wizard-nav-pills>li.active>a {
        background: transparent !important;
    }

    .wizard-nav {
        padding: 15px;
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    }

    .wizard-icon-circle {
        width: 35px;
        height: 35px;
        font-size: 14px;
    }

    .wizard-nav-pills>li.active>a .wizard-icon, 
    .wizard-nav-pills>li.active>a:focus .wizard-icon, 
    .wizard-nav-pills>li.active>a:hover .wizard-icon {
        font-size: 18px;
    }

    .wizard-nav-pills > li.active > a .icon {
        font-size: 16px;
    }

    .wizard-tab-content {
        margin-bottom: 0;
        height: calc(100vh - 199px);
        overflow: auto;
    }

    .wizard-card-footer {
        padding: 15px;
    }

    .wizard-card-footer-right {
        padding-right: 85px;
    }

    .btn-next {
        margin-right: 85px;
    }
}

.wizard-card-footer {
    background: #fff;
    box-shadow: 0px -0.5px 6px 0 rgba(0,0,0,.22);
}