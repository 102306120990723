$layered-menu-top-height: 50px;

.layered-menu {
    height: calc(100% - $layered-menu-top-height);
}

.layered-menu__top {
    padding: 10px 5px;
    height: $layered-menu-top-height;
}

.layered-menu__top-title {
    font-size: 15px;
    padding-right: 10px;
}

.layered-menu__list {
    padding: 0 !important;
    margin: 0;
    list-style: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    transition: transform 0.3s;

    visibility: hidden;
}

.layered-menu__item-label {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    padding: 10px 15px;

    &.active {
        background: $bg-silver;
        color: $primary;
    }
}

.layered-menu__list.active > li > .layered-menu__list:not(.active) {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.layered-menu__list.active {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
}