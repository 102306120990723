@import "../variables";

$panel-margin: 16px;

.oc-tab-users {
    margin-top: 16px;
}

.oc-users-includes-excludes-wrapper {

    .oc-users-includes-excludes-header {
        display: flex;
        justify-content: space-between;
        padding: 0 16px 0 16px !important;
    }

}

.oc-teams-users-list-container {
    padding: 0 16px 0 16px;

    .oc-teams-users-list-sub-heading {
        display: block;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 15px;
    }

    .oc-teams-users-list-item {
        font-size: 16px;

        &:not(:empty):not(:last-child):after {
            content: ", ";
            padding-right: 3px;
        }
    }
}

.oc-users-form {
    .oc-user-form-label-inline {
        display: inline;
    }

    .oc-user-divider-margin-md {
        margin-top: 25px;
    }

    .oc-user-divider-margin-lg {
        margin-top: 50px;
    }
}