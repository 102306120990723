.el-input {
    font-size: 12px;
    width: 100% !important;

    &.is-disabled {
        .el-input__inner {
            background: $input-disabled-background;
            opacity: 0.6;
            border: 1px solid $input-border-color;
            color: $text-disabled-input-color;

            &::placeholder { /* Chrome/Opera/Safari */
                color: $text-disabled-input-color;
            }

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $text-disabled-input-color;
            }

            &::-moz-placeholder { /* Firefox 19+ */
                color: $text-disabled-input-color;
            }

            &:-ms-input-placeholder { /* IE 10+ */
                color: $text-disabled-input-color;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: $text-disabled-input-color;
            }

        }
    }
}

.el-input--small {
    .el-input__inner {
        line-height: 15px;
    }
}

.el-input__inner {
    height: 34px;
    border-radius: 3px;
    border: 1px solid $input-border-color;
}

.el-textarea__inner {
    border-radius: 3px;
    border: 1px solid $input-border-color;
    font-size: 12px;
    padding: 5px 10px;
}

.multiselect--ribbon {
    .el-input__inner {
        height: 30px !important;
    }
}

.el-input__inner::-webkit-input-placeholder,
.el-textarea__inner::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $text-color;
}

.el-input__inner::-moz-placeholder,
.el-textarea__inner::-moz-placeholder { /* Firefox 19+ */
    color: $text-color;
}

.el-input__inner:-ms-input-placeholder,
.el-textarea__inner:-ms-input-placeholder { /* IE 10+ */
    color: $text-color;
}

.el-input__inner:-moz-placeholder,
.el-textarea__inner:-moz-placeholder { /* Firefox 18- */
    color: $text-color;
}

.el-input--no-border {
    .el-input__inner {
        border-color: transparent;
        background-color: transparent;

        &:hover {
            border-color: $input-border-color;
        }

        &:focus {
            border-color: $primary;
        }
    }
    &.has-error .el-input__inner{
        border-color: #FF6363;
        background-color: #FFEDED;
    }
}

.el-input--ellipsis {
    .el-input__inner {
        text-overflow: ellipsis;
    }
}

.el-input--bold {
    .el-input__inner {
        font-weight: 600;
    }
}

.el-input__char-count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    right: 10px;
    line-height: 1;
}

/*INPUT GROUP*/

.input-group-addon {
    border: 0;
    border-top: 1px solid $input-border-color;
    border-bottom: 1px solid $input-border-color;
}

.el-input-group > .el-input__inner {
    display: inline-block;
}

/* Reveal Password Input - User Settings Page*/
.reveal {
    position: relative;
}

.reveal__eye{
    position: absolute;
    left: 10px;
    top: 15px;
    font-size: 1.2em;
    cursor: pointer;
}

.client_secret {
    padding-left: 30px;
}

.password-toggle {
    font-size: 1.2em;
}
/* End Reveal Password */

.input-multiple .el-input-group__append {
    padding: 0;
}

.input-multiple .el-input {
    &:not(:first-child) {
        margin-top: 10px;
    }
}

.input-multiple .el-input-group__append button {
    background: none;

    &:active,
    &:hover,
    &:focus {
        background: none;
    }

    &:nth-child(2) {
        border-left: solid 1px silver;
        border-radius: 0 2px 2px 0;
    }
}

@media screen and (min-width: 768px) {
    .reveal__eye {
        position: absolute;
        left: 23px;
        top: 24px;
        font-size: 1.2em;
        cursor: pointer;
    }
}