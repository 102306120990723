.media-card {
    background: $bg-silver;
    margin-bottom: 25px;
    border-radius: 3px;
    -webkit-box-shadow: $panel-shadow;
    box-shadow: $panel-shadow;
}

img.media-card-image {
    width: auto;
    max-height: 200px;
    max-width: 100%;
    margin: 0 auto;
    display: table;
    /*box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);*/
}

.media-card__header{
    height: 50px;
    padding: 10px 15px;
    background-color: #fff;
}

.media-card__title {
    margin: 0;
    line-height: 2.2rem;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.media-card__subtitle {
    color: $text-color;
    font-size: 12px;
}

.media-card__image-container {
    padding: 15px;
    min-height: 232px;
    background: $bg-silver;
    border-radius: 3px 3px 0 0;
}

.media-card__tags, .media-card__tag {
    padding-bottom: 5px;
}

.media-card__info {
    padding: 10px 15px;
    height: 100px;
}

.media-card__type, .media-card__type {
    color: $text-color;
    font-size: 12px;
}

.btn--media-select {
    min-width: 125px;
}
