.pagination-count {
    float: left;
    padding: 8px;
    margin-right: -5px;
}

.el-pagination {
    .btn-next,
    .btn-prev {
        display: inline-block;
        padding-left: 0;
        border-radius: 4px;
        padding-right: 5px;

        span {
            padding: 6px 12px;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
            line-height: 15px;
            border-radius: 4px;
            font-weight: normal;
        }

        span:hover {
            color: #242a30;
            background: #e2e7eb;
            border-color: #d8dde1;
        }

        span:active {
            background: #242a30 !important;
            border-color: #242a30 !important;

        }
    }
}