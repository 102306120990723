$tiny-switch-height: 16px;

.el-switch--tiny {
    height: $tiny-switch-height;

    .el-switch__core {
        height: $tiny-switch-height;
        width: 40px;

        .el-switch__button {
            height: 10px;
            width: 10px;
        }
    }

    &.is-checked {
        .el-switch__button {
            transform: translate(26px, 2px) !important;
        }
    }
}

.el-switch-container {
    display: flex;

    label {
        margin-bottom: 0;
    }

    .control-label {
        line-height: 1.75;
        margin-bottom: 0;
        margin-right: 5px;
    }
}

.el-switch.is-checked .el-switch__core {
    border-color: #13ce66;
    background-color: #13ce66;
}