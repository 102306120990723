.media-body p {
    color: #242a30;
    margin-bottom: 5px;
}

.media-body--wrap {
    display: inline-block;
    max-width: 100%;
    word-wrap: break-word;
}

.media,
.media-body {
    overflow: visible;

    a.external-link:link, a.external-link:active, a.external-link:visited {
        text-decoration: none;
        color: #3071A9;
    }   
}

.media:after,
.media-body:after{
    content:'';
    clear:both;
    display:block;
}

.media .media-object--print-preview {
    width: 90px;
    height: auto;
    margin: 2px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
}

.media-body--middle {
    vertical-align: middle;
}

media-left.media-middle.media-print {
    height: 116px;
}

.media>.media-left, .media>.pull-left {
    padding-right: 15px;
}

.media.media-sm.media-library .media-object {
    width: 90px;
}

.loan-stat:last-child {
    border: 0;
}

.media-object.glyphicon-folder-open {
    font-size: 48px;
    color: hsl(40,60%,80%);
    text-align: center;
}

.group-card .media-object.glyphicon-folder-open {
    font-size: 128px;
    top: 30px;
}

.media-group-container {
    margin-top: 24px;
}

.media-group-container li.media {
    list-style-type: none;
    margin-top: 15px;
}

.group-card.expanded > .media-card > .media-group-container {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #e8ecee;
}

.group-card.expanded > .media-card > .media-card__image-container {
    display: none;
}

.group-name{
    padding-left: 0;
}

.group-card.expanded > .media-card .media-card > .media-card__image-container {
    background-color: #f5f6f8;
}


.group-card .media-card .media-card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.22);
}

.media-gifts-description {
    padding: 10px 0;
    font-size: 14px;
    color: inherit;
}

.media-gift-price {
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 10px;
}

p.media-description {
    color: #707478;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.45;
    word-break: break-word;
}

.media-object-sm {
    width: 90px;
    height: auto;
}

i.mce-i-swap-icon:before {
    content: "\f021";
    font-family: FontAwesome;
}

i.mce-i-location-icon:before {
    content: "\f041";
    font-family: FontAwesome;
}

i.mce-i-radius-icon:before {
    content: "\f140";
    font-family: FontAwesome;
}

i.mce-i-remove-type-icon:before {
    content: "\f1f8";
    font-family: FontAwesome;
}

#google-map {
    height: calc(100vh - 150px);
    margin: 16px auto 0 auto;
    background: gray;
}

#place-search-body-container {
    max-height: calc(100vh - 115px);
}

#place-results-container {
    margin: 16px auto 0 auto;
    max-height: inherit;
    overflow-y: scroll;
}

#great-schools-google-map {
    height: calc(100vh - 150px);
    margin: 16px auto 0 auto;
    background: gray;
}

#school-search-body-container {
    max-height: calc(100vh - 115px);
}

#school-results-container {
    margin: 16px auto 0 auto;
    max-height: inherit;
    overflow-y: scroll;
}