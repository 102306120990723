/* Lead Info (Lead View Page)
================================================== */

.leadSourceCol {
    width:250px;
}

.lead-info-box {
    height: 100%;
}

.lead-stat {
    margin: 10px 0 10px 10px;
}

.lead-stat-label {
    width: 145px;
    padding-right: 15px;
    float: left;
}

.lead-stat-label .lead-stat-icon {
    width: 10px;
    text-align: center;
    margin-right: 5px;
}

.lead-address {
    margin: 10px 0px;
}

.lead-stat-label strong {
    color: #242a30;
    font-weight: 400;
}

.lead-label h5 {
    display: inline;
    background: #fff;
    padding-right: 10px;
    font-weight: 600;
}

.lead-label--filter h5 {
    font-size: 16px;
    color: #929ba1;
}

.lead-label {
    color: #242a30;
}

.lead-header-container {
    position: relative;
    padding: 6px 0px;
    margin-top: 5px;
}

.lead-stripe {
    position: relative;
}

.lead-header-container hr {
    margin: 0;
    margin-top: 7px;
    position: absolute;
    left: 1rem;
    right: 0;
    margin-top: .9rem;
    margin-bottom: 0;
}

.lead-stat-value {
    float: right;
}

.lead-source-boxes{
    padding:5px;
    border:1px #ccc solid;
}

.avatar-box__image img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.avatar-box__info-box {
    float: left;
    padding-left: 15px;
}

.avatar-box__name {
    margin: 0;
}

.fb-blue {
    background: #3B5998;
    color: #fff;
}

.twitter-blue {
    background: #00aced;
    color: #fff;
}

.linkedin-blue {
    background: #007bb6;
    color: #fff;
}

.image-50 {
    width: 50px;
    height: auto;
}

.library-image {
    border:2px #ececec solid;
    padding:5px;
    margin:5px;
    cursor:pointer;
}

[data-toggle="collapse"] {
    cursor: pointer;
}

.collapse-header--arrow {
    display: block;
    width: 100%;
    padding-bottom: 6px;
    border-bottom: 1px solid #eee;
}

.collapse-header--arrow.no-border {
    border: 0;
}

.collapse-header--arrow.active > i {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(0.55,0,0,1);
    transition: transform .2s cubic-bezier(0.55,0,0,1);
}

.collapse-header--arrow > i {
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(0.55,0,0,1);
    transition: transform .2s cubic-bezier(0.55,0,0,1);
}

.lead-smart-group-icon {
    font-size: 15px;
    color: #ccc;
}

/* Lead View General Note
=========================================== */

.lv-gn__note {
    padding-right: 22px;
    margin-bottom: 5px;
}

@media only screen and  (min-width: 400px) {
    .lead-stat-label {
        width: 180px;
    }
}

/* Lead View Account Information
========================================== */

.lv-ai-remove-button {
    background-color: #ffff;
    color: #ff0000;
    border: 1px solid #ff0000;
}

.lv-ai-panel-body {
    max-height:425px;
    overflow-y:auto;
    overflow-x:hidden
}

/* Campaign History
=========================================== */
.lv-campaign-list {
    padding: 0;
}

.lv-campaign-list__item {
    display: flex;
    padding-bottom: 6px;
    border-bottom: 1px solid #eee;
}

.lv-campaign-list__item h5 {
    font-weight: 600;
    line-height: 15px;
}

.lv-campaign-item-content__left {
    flex: 80%;
}

.lv-campaign-item-content__right {
    flex: 20%;
    text-align: right;
}
