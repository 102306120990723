$head_size: 28px;
$head_wait_color: #ffffff;
$head_active_color: #348fe2;

$line_inactive_color: #bfcbd9;
$line_width: 2px;

$icon_height: #{$head_size - (2 * $line_width)};

.stepper-list {
    margin-right: #{$head_size / 2};
}

.stepper-list.stepper-list-flush {
    border-radius: 0;
    margin-bottom: 0;
}

.stepper-list.stepper-list-flush > .stepper-list-item {
    border-radius: 0;
}

.stepper-list-item {
    border-right: $line-width solid;
}

.stepper-list-item__head {
    position: absolute;
    right: #{-1 * ($head_size / 2)};
    top: calc(50% - #{$head_size / 2});
    width: $head_size;
    height: $head_size;
    line-height: $head_size;
    text-align: center;
    vertical-align: top;
    border-radius: 50%;
    border: $line_width solid;
    font-size: #{$head_size / 2};
}

.stepper-list-item.wait {
    border-right-color: $line_inactive_color;
}

.stepper-list-item.wait .stepper-list-item__head {
    background-color: $head_wait_color;
    border-color: $line_inactive_color;
}

.stepper-list-item.process {
    border-right-color: $head_active_color;
    background-color: $head_active_color;
    color: $head_wait_color;
}

.stepper-list-item.process .stepper-list-item__title {
    color: $head_wait_color;
}

.stepper-list-item.process .stepper-list-item__head {
    background-color: $head_active_color;
    border-color: $head_wait_color;
    color: $head_wait_color
}

.stepper-list-item.finish {
    border-right-color: $head_active_color;
}

.stepper-list-item.finish .stepper-list-item__head {
    background-color: $head_active_color;
    border-color: $head_wait_color;
    color: $head_wait_color;
}

.stepper-list-item__icon {
    display: block;
    line-height: #{$head_size - (2 * $line_width)};
}

.stepper-list-item__icon > * {
    line-height: inherit;
    vertical-align: middle;
}