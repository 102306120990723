.org-logo {
    margin: 0 auto;
    display: table;
    position: relative;
}

.org-logo__img {
    height: auto;
    width: 100%;
}

.org-logo__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 0;
    top: 0;
    left: 0;
}

.org-logo:hover .org-logo__overlay {
    opacity: 1;
}