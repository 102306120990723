@import "../variables";

$panel-vertical-margin: 16px;
$scroll-horizontal-padding: 40px;
$scroll-vertical-padding: 20px;
$scroll-min-height: 200px;
$list-header-height: 105px;
$form-header-height: 95px;
$gutter-size: 15px;

.oc-email-event-date-time {
    form {
        .vqb-rule-input-row {
            display: flex !important;
            line-height: 2;

            .vqb-rule-input {
                margin-right: 2vw;
            }
        }
    }
}

.oc-page-form {
    .campaign-state {
        pointer-events: none;
    }

    .campaign-form-back-button {
        margin-left: -25px;
        margin-right: 5px;
    }
}

.oc-tab-overview {

    .overview-flex-panel-row {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        flex-direction: row;
        align-items: flex-start;
        margin-left: -1 * $gutter-size;
        margin-right: -1 * $gutter-size;

        .overview-flex-panel-item {
            flex: 1;
            padding-left: $gutter-size;
            padding-right: $gutter-size;
        }
    }

    .oc-tab__title {
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .oc-overview-panel {
        min-height: 140px;
        height: 100%;
    }

    .availability-panel {
        line-height: initial !important;
    }

    .data-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .fb-ad-overview {
        width: 55%;
        border-right: 1px solid lightgrey;
    }

    .budget-overview {
        width: 45%;
        padding-left: 20px;
    }
}

.oc-availability-container {
    label {
        margin-bottom: 0 !important;

        .el-radio__label {
            .oc-availability-sub-text {
                display: block;
                margin-left: 24px;
                line-height: 16px;
                white-space: normal;
            }
        }
    }
}

.oc-audience-processing-status-text {
    text-transform: capitalize;
}

