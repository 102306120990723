.password-requirements-wrap {
    padding: 0;
}

ul.rule-display{
    list-style: none;
    padding-left: 0;
    line-height: 1em;
}

.co-password-icon i {
    font-size: 1.2em;
}

.ps-form {
    width: 330px;
}

.ps-form__label {
    width: 280px;
}

.ps-form__toggle-label {
    width: 220px;
}

.ps-mfa-form__toggle-label{
    width: 310px;
}

.ps-form__action,
.ps-form__label,
.ps-form__toggle-label,
.ps-mfa-form__toggle-label{
    display: inline-block;
}

.ps-mfa-form__checkbox {
    margin: 0;
}

.ps-mfa-form__checkbox .el-form-item__content {
  line-height: 20px;
}

.ps-form__hr {
    border-color: #ccd0d4;
}

.ps-mfa-form__info-text {
    font-size: 14px;
    line-height: 1.1em;
    font-weight: 200;
}

.ps-mfa-form__info-text strong {
    font-weight: 400;
}

.ps-mfa-form__label {
    color: #333333;
    font-size: 16px;
    font-weight: 200;
    line-height: 1em;
    margin-top: 10px;
}

.ps-tooltip {
    font-size: 14px;
}

.ps-tooltip-content {
    max-width: 270px;
}

.ps-auto-reset {
    color: #333333;
}

.ps-mfa-header {
    margin: 0;
}

.ps-mfa-info {
    margin-top: 1rem !important;
    width: 500px !important;
}

.ps-mfa-panel {
    padding-left: 32px;
    padding-top: 32px;
}

.ps-mfa-methods-table {
    width: 500px !important;
    border: solid 1px #CCCCCC;
    margin: 32px 0 0;
}

.ps-mfa-methods-table table {
    margin: 0;
}

.ps-mfa-method-action-btn {
    padding: 0;
}

.ps-mfa-methods-table th {
    font-size: 14px;
}

.ps-mfa-reset-confirm {
    font-size: 16px;
    width: auto;
}

.ps-mfa-reset-confirm .el-message-box__content {
    padding: 20px 20px;
}

.ps-mfa-reset-confirm .el-message-box__message p {
    font-size: 1em;
    line-height: 24px;
}

.ps-mfa-reset-confirm .el-message-box__btns {
    border-top: solid 1px #eee;
}


.org-security-questions-panel {
    padding: 10px;

    .security-question-buttons {
        float: right;
    }

    .security-question-toggle {
        margin-top: 5px;
    }

    .el-form-item__content {
        line-height: 28px;
    }

    .el-checkbox__label {
        width: 440px;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .custom-questions {
        .el-checkbox__label {
            width: 260px;
        }

        .el-checkbox__input {
            vertical-align: middle;
            padding: 0;
        }
    }

    .el-checkbox__input {
        vertical-align: sub;
        padding-top: 5px;
    }
}

.org-security-question-settings-form-label {
    width: 300px;
    display: inline-block;
}

.org-security-question-settings-btn {
    background:none;
    border:none;
    margin:0;
    padding:0;

    &:hover {
        background-color: transparent;
        text-decoration: none;
    }
}

.org-security-question-settings-btn-txt {
    color: #348fe2 !important;
}

.org-security-question-settings-txt {
    color: #707478 !important;
}

.security-settings-tab-pane {
    margin-left: 32px;
    padding-left: 0;
}

.security-settings-tab-pane .row {
    margin-left: 0 !important;
    width: 650px;
}

.security-settings-tab-pane .panel {
    padding: 24px !important;
    width: 100% !important;
}

.security-questions-subheader {
    margin-top: 1.5rem;
}

.reqLabel {
    margin-top: 24px;
}

.red {
    color: red;
}

.security-questions-left {
    width: 60%;
    float: left;
}

.security-questions-right {
    width: 35%;
    float: right;
}

.security-questions-right p {
    color: #AAAAAA;
}

@include media-sm {
    .ps-form {
        width: 500px;
    }

    .password-requirements-wrap {
        padding: 0 0 0 20px;
    }

    .password-requirements-wrap--registration {
        padding: 73px 0 0 20px;
    }
}
