.board {
    user-select: none;
    white-space: nowrap;
    margin-bottom: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 2px;
}

.panel-title--boards {
    font-size: 15px;
    font-weight: 600;
    padding: 15px;
    color: $text-black;
}

.panel-body--board {
    max-height: calc(100vh - 211px);
    height: auto;
    overflow-y: auto;
    padding: 0;
}

.board-search-container + .panel-body--board {
    max-height: calc(100vh - 256px);
}

.board-search-container {
    padding: 0 15px 15px;
}

.swim-lane {
    display: inline-block;
    vertical-align: top;
}

@include media-sm {
    .panel-body--board {
        padding: 15px;
    }
}

@include media-md {
    .panel-body--board {
        max-height: calc(100vh - 200px);
    }

    .board-search-container + .panel-body--board {
        max-height: calc(100vh - 245px);
    }
}

@include media-max-md {
    .swim-lane {
        width: 100vw;
    }
}