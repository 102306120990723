html, body {
    height: initial;
}

body {
    background: #e8e8e8;
    font-size: 13px;
}

.xdebug-error {
    position: fixed;
    z-index:100000;
    bottom:0;
    right:0;
    background-color:#ffffff;
    border:1px #000000 solid;
    padding:10px;
    overflow-y:scroll;
    height:200px;
    width:1000px;
}

table.xdebug-error.xe-warning {
    margin: 90px 15px 0 245px;
}

/*#content.content {
    padding-bottom: 70px;
}*/

a {
    cursor: pointer;
}

a:focus,
a:hover {
    text-decoration: none;
}

#content.content {
    margin-top: 44px;
}

/*gets rid of #content.content margin-top*/
.no-ribbon {
    margin-top: -36px;
}

.page-header.page-header--full-table {
    line-height: normal;
    font-size: 24px;
    font-weight: 300;
    color: #333;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 10px 15px;
}

.d-flex.row:after,
.d-flex.row:before {
    display: flex;
}

#fc_frame,
#fc_frame.fc-widget-normal {
    z-index: 2000 !important;
}

@media only screen and (max-width: 767px) {
    .page-header-fixed {
        padding-top: 55px;
    }

    #content.content {
        margin-top: 45px;
        padding: 10px 10px 35px 10px;
    }
}

@media print {

    @page {
      margin: 0;
    }

    body {
        height: 100%;
        width: 100%;
        background-color: #fff !important;
    }

    .hidden-print {
        display: none !important;
    }

    table.table--pretty th, table.table--pretty td {
        white-space: normal;
    }

    .tooltip {
        display: none !important;
    }

    #page-container,
    #content,
    .panel-body{
        margin:0;
        padding:0;
    }

    .table>tbody>tr>th,
    table.dataTable thead th,
    .table>tbody>tr>td {
        padding:5px;
    }

    a:link:after, a:visited:after {
        content: "";
    }

    .leadSourceCol,
    .leadSourceWrap {
        width:130px;
    }
}