.update-password-form {
    overflow: hidden;
    width: 95%;
    min-height: 532px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.upf-logo {
    position: relative;
    padding: 20px;
    text-align: center;
    border: solid 1px silver;
}

.upf-logo img {
    height: 60px;
}

.upf-component {
    padding: 20px 0;
    overflow: hidden;
}

.upf-component__text-wrap {
    margin: 0 0 30px 0;
    text-align: center;
}

.upf-component__subtitle {
    color: rgba(36, 42, 48, .7);
}

.upf-component__content {
    padding: 0px 20px 10px 20px;
    overflow: hidden;
}

.upf-component__button-wrap {
    padding: 0 20px;
}

.upf-component__button-wrap button {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .update-password-form {
        width: 400px;
    }
}
