.task-title-cell {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.task-title-cell__complete {
    color: #d6d6d6;
    font-size: 22px;
    cursor: pointer;
}

.task-title-cell__complete.is-complete {
    color: #00acac;
}

.task-title-cell__title  {
    padding-left: 10px;
}

.task-title-cell__title.is-complete {
    text-decoration: line-through;
}