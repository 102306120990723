.panel {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
}

.panel-inverse .panel-heading {
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.panel-inverse.panel-border {
    .panel-heading {
        border-bottom: 1px solid #eee !important;
    }
}

.panel-inverse .panel-heading-btn {
    line-height: normal;
    padding: 6px 0;
}

.panel-heading .panel-title {
    line-height: normal;
    font-size: 24px;
    font-weight: 300;
    color: #333;
}

.panel-heading .panel-subtitle {
    line-height: normal;
    font-size: 24px;
    font-weight: 300;
    color: #333;
    padding-left: 6px;
}

.panel-inverse > .panel-heading {
    background: transparent !important;
}

.panel-inverse > .panel-footer {
    background: transparent !important;
}

.panel-heading-right {
    float: right;
}

.panel-heading__divider {
    align-self: stretch;
    height: auto;
}

.panel--loader .infinite-status-prompt {
    padding: 18px 0;
    background: $bg-silver;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel-body--no-padding {
    padding: 0;

    .floated thead {
        border-top: 0;
    }
}

.panel-footer {
    .pagination {
        margin: 0;
    }
}

.panel-secondary-header {
    padding: 15px;
    border-bottom: 1px solid $panel-border-color;
}

.panel--bordered {
    box-shadow: none;
    border: 1px solid $panel-border-color;
}

.panel--bordered-top-bottom {
    box-shadow: none !important;
    border-top: 1px solid $panel-border-color;
    border-bottom: 1px solid $panel-border-color;
}

.panel--borderless {
    box-shadow: none !important;
    border: 0px;
}

.panel-heading + .note {
    margin-bottom: 0;
    border-left: 0;
}

.panel-body {
    position: relative;
}

.panel__disabled {
    background-color: rgba(255,255,255,0.8);
    color: #333;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 3;
    position: absolute;
    font-size: 16px;
}

.panel-body-media-library {
    min-height: 156px;
}

.panel-body--loan-profile {
    padding: 18px 15px;
}

.panel-title.panel-title--no-header {
    font-size: 16px;
    color: #333;
    padding-bottom: 10px;
}

.panel-title-container {
    display: flex;
    align-items: baseline;
}

.panel-title-container__vertical {
    flex-direction: column;

    .panel-subtitle {
        padding-left: 0;
    }
}

.panel-title-container__horizontal {
    flex-direction: row;

    .panel-subtitle {
        padding-left: 6px;
    }
}

.panel-empty {
    color: #707478;
}

.panel-empty__title {
    color: #707478;
    font-weight: 600;
}

.panel-empty__icon {
    font-size: 50px;
}

.tab-content-media-library {
    padding: 0;
    background: transparent;
}

.panel-inverse > .panel-heading {
    color: inherit;
}

.panel--flex-full-height {
    height: calc(100% - 20px);
}

.panel-group-flex {
    display: flex;
    flex-flow: row wrap;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 3px;
}

.panel-group-flex .panel {
    border-radius: 3px;
    flex: 1 0 0%;
    flex-direction: column;
    display: flex;
    box-shadow: none;
    margin-bottom: 0;
}

.panel-group-flex>.panel:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.panel-group-flex>.panel:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #eee;
}

.panel-group-flex .panel+.panel {
    margin-top: 0;
}

@media screen and (max-width: 415px) {

    .panel-heading > .panel-title {
        font-size: 16px;
        font-weight: 400;
    }

    .panel-inverse .panel-heading-btn {
        line-height: normal;
        padding: 0;
    }
}