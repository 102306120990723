.el-select {
    width: 100%;

    .el-tag {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
    }

    &.wrap-tag .el-tag {
        white-space: normal;
        height: 100%;
    }
}

.el-select--fit {
    &.el-select--fit {
        width: auto;
    }

    .el-input .el-input__icon {
        font-size: 9px;
    }

    .el-input__icon {
        width: 20px;
    }

    .el-input__inner {
        padding-right: 20px;
        width: 110.5px;
    }

    .el-select-dropdown__item, .el-dropdown-menu__item {
        padding: 5px 15px;
    }
}

.el-select-dropdown__item {
    height: auto;
    font-size: $font-size-base;

    span {
        line-height: initial;
    }

    &:hover.no-hover {
        background: none;
    }
}

.el-select-dropdown__item.selected {
    color: #fff;
}

.truncate--tags {
    .el-tag {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 2px;

        top: 2px;
        position: relative;
    }
}

.multiselect--ribbon {
    max-width: 200px;
}

.media-list-select {
    padding: 10px 0;
    overflow: hidden;
}

.media-list-select__thumbnail {
    width: 200px;
    height: auto;
    float: left;
}

.media-list-select__body {
    float: left;
    padding-left: 10px;
}

.media-list-select__title {
    padding-bottom: 5px;
}

.media-list-select__social-icon {
    font-size: 19px;
    padding-right: 5px;
}


/*selectpicker AKA Bootstrap Select*/

select.selectpicker {
    display: none;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="span"] {
    margin-bottom: 0;
}

.bootstrap-select .dropdown-menu .bs-actionsbox  {
    padding-top: 10px;
}

.input-group .btn.dropdown-toggle.selectpicker.btn-white {
    border-radius: 3px 0 0 3px;
    border-right: none;
    border-color: #e2e7eb;
}

.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left: 0;
    display: block;
}

/* Media queries */
@media (max-width: 767px) {
    .el-dropdown-menu, .el-select-dropdown {
        width: 90% !important;
    }
}
