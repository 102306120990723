#co-register-page {
    margin: 0 !important;
    overflow-x: hidden;
    position: relative;
    font-family: 'Open Sans', serif !important;
    background-color: #fff;
}

#js-co-register-register-form {
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 4px;
    box-shadow: gray 0 0 7px;
}

#js-co-register-register-form label {
    font-size: 1em;
    font-weight: 600;
}

#js-co-register-register-form fieldset {
    display: block;
    -webkit-margin-start: 2px;
    -webkit-margin-end: 2px;
    -webkit-padding-before: 0.75em;
    -webkit-padding-start: 1.5em;
    -webkit-padding-end: 1.5em;
    -webkit-padding-after: 0.3em;
    min-width: -webkit-min-content;
    border-width: 1px;
    border-style: solid;
    border-color: threedface;
    border-image: initial;
    margin-bottom: 20px;
    border-radius: 2px;
    border-color: #bebebe;
}

#js-co-register-register-form legend {
    display: block;
    -webkit-padding-start: 2px;
    -webkit-padding-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    width: auto;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
}

.co-register-header {
    width: 100%;
    height: 45px;
}

.co-register-header strong {
    font-weight: 400;
}

.co-register-header-image {
    position: absolute;
    left: 10px;
}

.co-register-header-image img {
    height: 50px;
    width: auto;
    float: left;
    padding: 5px;
    margin-top: -2px;
}

.co-register-section-title {
    font-weight: 400;
    margin-top: 0;
    font-size: 21px;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
}

/*---------------
Main Info
---------------*/

.co-register-main-info {
    height: 400px;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    overflow:auto;
    position: relative;
}

.co-register-main-info__title {
    font-family: 'Open Sans', serif;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    letter-spacing: 4px;
    line-height: 1.5;
    margin-top: 150px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
}

.co-register-main-info__sub-title {
    font-family: 'Open Sans', serif;
    font-weight: 300;
    font-size: 15.2px;
    color: #fff;
    margin: 0 0 35px 0;
    text-align: center;
    position: relative
}

.co-register-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.4);
}

/*-------------
Feature Set
--------------*/
.co-register-feature-section{
    padding: 25px 0;
    background-color: #3474b7;
    position: relative;
}

.co-register-feature-wrap{
    text-align: center;
    margin: 0 0 30px 0;
}

.co-register-feature-icon{
    margin: 0 auto 10px;
    border-radius: 4px;
    height: 60px;
    width: 75px;
}

.co-register-feature-icon img{
    margin-top: 10px;
    width: 47px;
}

.co-register-feature-title{
    font-size: 18.2px;
    font-weight: 400;
    margin: 0 0 5px;
    color: #fff;
}

.co-register-feature-text{
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    padding: 0 15px;
    color: #fbfbfb;
}

.co-register-feature-section::after {
    bottom: -25px;
    z-index: 10;
    background: inherit;
}

.co-register-feature-section::before,
.co-register-feature-section::after {
    left: 50%;
    width: 50px;
    height: 50px;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
}

section::before,
section::after {
    position: absolute;
    content: '';
    pointer-events: none;
}

.co-register-register-form{
    /* background-color: #ffffff; */
    /* background: #61b6d8; */ /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #61b6d8 0%, #0093d0 100%); /* FF3.6-15 */
    /* background: -webkit-radial-gradient(center, ellipse cover, #61b6d8 0%,#0093d0 100%); */ /* Chrome10-25,Safari5.1-6 */
    /* background: radial-gradient(ellipse at center, #61b6d8 0%,#ffffff 100%); */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b6d8', endColorstr='#0093d0',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    padding: 75px 0;
}

.co-register-register-button {
    background-color: #3474b7;
    color: white;
    border-radius: 13px;
    max-width: 130px;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 10px;
}

.co-register-blue-bg{
    background-color: #3474b7;
}

.co-register-color-white{
    color: #242a30;
}

.co-register-copyright {
    font-size: 10px;
}

.co-register-details {
    color: rgb(153, 153, 153);
    font-size: 12px;
    font-weight: 500;
}

.co-register-required-text {
    color: rgb(85, 85, 85);
    font-size: 12px;
}

@media screen and (max-width: 430px) {
    .co-register-main-info {
        background: rgb(33,142,194);
        background: linear-gradient(0deg, rgba(33,142,194,1) 0%, rgba(38,94,172,1) 100%);
    }

    .co-register-feature-section {
        background-color: #707478;
    }
}

@media screen and (min-width: 768px){

    .co-register-main-info__title {
        font-size: 35px;
    }

    .co-register-main-info__sub-title {
        font-size: 18.2px;
    }

    .co-register-section-title {
        font-size: 26px;
    }
}

@media screen and (min-width: 992px) {
    .co-register-register-button {
        float: right;
        margin-top: 0;
    }
    .co-register-register-button-text {
        float: right;
        text-align: right;
    }
}

@media screen and (min-width: 1100px){

    .co-register-main-info {
        background-image: url('./images/comarketing-header.jpeg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        overflow: auto;
        position: relative;
    }

    .co-register-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.4);
    }

    .co-register-main-info__title {
        font-size: 40px;
    }

    .co-register-main-info__sub-title{
        font-size: 21.2px;
    }
}

@media screen and (min-width: 1365px) {
    .co-register-main-info__product-image {
        height: 469px;
        width: 840px;
    }
}

@media screen and (min-width: 1930px) {
    .co-register-main-info {
        background: rgb(33,142,194) !important;
        background: linear-gradient(0deg, rgba(33,142,194,1) 0%, rgba(38,94,172,1) 100%) !important;
    }

    .co-register-feature-section {
        background-color: #707478;
    }
}