/*MEDIA QUERIES*/
@mixin media-sm {
  @media (min-width: #{$media-sm}) {
    @content;
  }
}

@mixin media-md {
  @media (min-width: #{$media-md}) {
    @content;
  }
}

@mixin media-lg {
  @media (min-width: #{$media-lg}) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: #{$media-xl}) {
    @content;
  }
}

/*MAX QUERY*/
@mixin media-max-sm {
  @media (max-width: #{$media-sm}) {
    @content;
  }
}

@mixin media-max-md {
  @media (max-width: #{$media-md}) {
    @content;
  }
}

@mixin media-max-lg {
  @media (max-width: #{$media-lg}) {
    @content;
  }
}

@mixin media-max-xl {
  @media (max-width: #{$media-xl}) {
    @content;
  }
}