.file-container {
    display: inline;
}

.file-name {
    color: #242a30;
    font-size: 1.1em;
    font-weight: 500;
}

.file-download {
    float: right;
}

.file-details {
    float: right;
    padding-right: 7px;
}

.no-overflow.panel-heading {
    overflow: initial;
}

.file-row {
    background-color: #fff;
    padding: 13px 15px;
    border-bottom: 1px solid #c1ccd1;
}

.details-panel {
    border-bottom: 1px solid #c1ccd1;
}

.btn.btn-sm.file-btn {
    display: inline;
}
