.collapse-list-container {
    overflow-y: auto;
    height: calc(100vh - 160px);
}

.collapse-list-group {
    background-color: #F8F8F8;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    transition: max-height 0.3s;
    overflow: hidden;

    .el-collapse-item__header {
        background-color: #F8F8F8;
        color: #626262;
        font-weight: bold;
    }
}

.collapse-list-group-hide {
    border: none;
    max-height: 0;
    visibility: hidden;
}
