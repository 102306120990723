/*THEME COLORS*/
$black: #242a30;
$danger: #ff5b57;
$info: #49b6d6;
$inverse: #2d353c;
$primary: #348fe2;
$success: #00acac;
$warning: #f59c1a;
$white: #ffffff;

$primary-light: #74bfff;
$primary-ultra-light: #d0e5f8;
$primary-dark: #0062b0;
$primary-color: $primary;
$bg-silver: #f0f3f4;
$sidebar-color: #020e3b;
$global-body-background-color: #edeff0;

/*HOVER COLORS*/
$danger-hover-color: #cc4946;
$dropdown-hover-color: #edf0f5;
$info-hover-color: #3a92ab;
$inverse-hover-color: #242a30;
$primary-hover-color: #2a72b5;
$success-hover-color: #008a8a;
$warning-hover-color: #c47d15;
$white-hover-color: #e2e7eb;

/*BORDER COLORS*/
$table-border-color: #e2e7eb;
$panel-border-color: #eee;
$focus-border-color: #9fa2a5;
$input-border-color: #ccd0d4;

/*TEXT COLORS*/
$black-text-color: #242a30;
$text-black: #242a30;
$text-grey: #b6c2c9;
$text-color: #707478;
$text-disabled-input-color: #555;

/*SHADOWS*/
$dropdown-shadow: 0 1px 5px 0 rgba(0,0,0,0.25);
$panel-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);

/*RADIUS*/
$base-radius: 3px;

/*MEDIA QUERY SIZES*/
$iphone-plus: 414px;
$media-sm: 576px;
$media-md: 769px;
$media-lg: 992px;
$media-xl: 1200px;

/*FONT SIZES*/
$font-size-base: 13px;
$input-base-font-size: 12px;

/*INPUTS*/
$input-disabled-background: #e5e9ed;

$table-selected-row-color: rgba(52, 143, 226, 0.13);

/* COMMON HEIGHTS */
$top-ribbon-height: 44px;
$global-header-height: 60px;