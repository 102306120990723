/* square
================================================== */

.upload-modal__top {
    margin-bottom: 15px
}

.square {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    border-radius: 3px;
    position: relative;
}

.square--cover {
    background-size: cover !important;
}

.square--contain {
    background-size: contain !important;
}

.square--select {

    &:hover {
        box-shadow: inset 0 0 0 3px $primary;
        cursor: pointer;
    }

    &.active {
        box-shadow: inset 0 0 0 3px $primary;

        .square__select-label {
            z-index: 1;
            position: absolute;
            left: 5px;
            top: 7px;
            line-height: inherit;
            display: inline-block;
            background: #13ce66;
            color: #fff;
            padding: 5px;
            border-radius: 50%;
            font-size: 8px;
            -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
        }
    }

    &.disabled, &.disabled:hover {
        box-shadow: none;
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.col-square-12 {
    width: 100%;
    padding-bottom: 100% !important;
    overflow: hidden;
}

.square-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 0;
    top: 0;
    left: 0;
}

.square:hover .square-overlay {
    opacity: 1;
}

.square-bottom {
    padding: 10px;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.square-top-center {
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 0;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
}

.square-top-right {
    padding: 10px;
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 0;
}

.square-top-left {
    padding: 10px;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
}

.square-bottom-right {
    padding: 10px;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.square-bottom-center {
    padding: 10px;
    position: absolute;
    left: 50%;
    bottom: 0;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
}


.square-bottom-left {
    padding: 10px;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.square-expand {
    color: #242a30;
    cursor: pointer;
}

.square-delete {
    color: #ff5b57;
    cursor: pointer;
}

.square-delete a {
    color: #ff5b57;
}

.square--img-tag img {
    width: 100%;
    height: auto;
}

.js-expand-image {
    margin: 0 auto;
    display: table;
    max-width: 100%;
}

.upload-modal__preview {
    margin-bottom: 15px;
    overflow: hidden;
    background: #f5f5f5;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    padding: 10px;
}

.js-image-preview {
    width: auto;
    height: 50px;
}

.image-preview-name {
    padding: 15px 15px;
}

.image-preview-btn {
    margin: 8px 0;
}