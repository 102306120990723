@import "../variables";

.notification-panel-message {
    margin-left: 10px;
    margin-right: auto;
}

.notification-panel-icon {
    width: 20px;
}


.notification-bell {
    &.notify {
        animation: ring 1.5s ease;
    }
}


.notifications-popover {
    min-height: 91px;
    background-color:#FAFBFC;
    margin-right: 12px;
    padding: 0px !important;

    &.el-popper {
        &[x-placement^="bottom"] .popper__arrow {
            &::after {
                border-bottom-color: $global-body-background-color;
            }
        }
    }

    & > div:nth-child(2) {
        max-height: calc(100vh - #{$global-header-height + 40});
        overflow: auto;
    }
    .el-popover__title{
        display: none;
    }
}

.dismiss-all-btn {
    float: right !important;
    border: none !important;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #0072CE;
    margin: 14px 18px 12px 0px;
    background-color: #FFFFFF;
    padding-top: 15px;
}

.notification-panel-title{
    display: inline-block !important;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    padding: 26px 0 15px 24px;
    color: #101820;
}

.notification-panel-header {
    background: #FFFFFF !important;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 15px;
}

.notification-badge {
    .el-badge__content {
        background-color: #753BBD;
        font-size: 12px;
        border: none;

        &.is-fixed {
            margin-right: 10px !important;
        }
    }
}

.notifications-panel {
    min-height: 50px;
    min-width: 400px;
}

.slideout-notification {    
    &.notifications-card  { 
        background-color: #F6FDFB;
    }
    .notification-card-title {
        font-size: 18px;
        line-height: 23px;
    }
    .notification-card-icon {
        font-size: 24px;
        line-height: 24px;
        margin: 0 8px 0 0;
        position: relative;
        top: 8px;
    }
    .notification-card-message {
        padding-left: 37px;
    }
}

@keyframes ring {
    0%    { transform: rotate(35deg); }
    12.5% { transform: rotate(-30deg); }
    25%   { transform: rotate(25deg); }
    37.5% { transform: rotate(-20deg); }
    50%   { transform: rotate(15deg); }
    62.5% { transform: rotate(-10deg); }
    75%   { transform: rotate(5deg); }
    100%  { transform: rotate(0deg); }
}

.el-badge-oval {
    sup.el-badge__content {
        padding: 0px 4px 0px 4px;
    }
}

.el-badge-circle {
    sup.el-badge__content {
        padding: 0px 6px 0px 6px;
    }
}

.notifications-container {
    max-height: 800px;
    position: fixed;
    right: -42px;
    top: 70px;
    width: 420px;
}

.notifications-card {
    padding: 13px 14px 13px 6px;
    position: relative;
    width: 378px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
    border-radius: 8px 0px 0px 8px;
    font-family: 'Source Sans Pro';

    .panel-body {
        padding: 0px;
    }
}
.notfication-panel-body{
    background: #FAFBFC;
    padding: 3px 15px;
}

.notification-card-unread {
    border-left: 5px solid #48d597;
    background-color: #F6FDFB;
}

.notification-card-read {
    border-left: 5px solid transparent;
}

.notification-card-dismiss-btn {
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    display: none;
    align-items: center;
    text-align: center;
    position: absolute;
    left: -25px;
    top: -25px;
    cursor: pointer;
}

.notfication-panel-body:hover .notification-card-dismiss-btn{
    display: flex;
}


.notifications-animation {
    animation: notificationAnimation .7s;
    animation-direction: alternate;
    animation-timing-function:linear;
}

@keyframes notificationAnimation {
    0%   {left: 378px;}
    100% {left: 0px;}
}

.notification-card-icon {
    font-family: 'Font Awesome 6 Pro';
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    width: 28px;
    color: #27A76E;
    padding: 0px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-card-text {
    display: inline-block;
    padding-left: 12px;
    overflow: hidden;
}

.notification-card-title {
    max-height: 23px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: start;
    white-space: nowrap;
}

.notification-date-time {
    color: #667a81;
    padding: 0px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-card-message {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-left: 29px;
    max-height: 30px;
    color: #00263A;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
}

.notification-card-button {
    float: right;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #0162C0;
    padding-top: 5px;
    background-color: #FFFFFF;
}


.notification-action-button {
    border: none;
    color: #0162C0;
    padding: 11px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;    
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent; 
    border: none;
  }
  
  .notification-action-button:hover {
    background-color: #F2F8FC;
    color: #0072CE;
  }
