/********** start stream here **************/

.timeline:before {
    display:none;
}

#timeline-spinner {
    position: relative;
    width: 100%;
    height: 65px;
    margin-top: 10px;
}

.activity-inner-row {
    margin-bottom: 15px;
    border-radius: 3px;
}

.stream-time-header h5 {
    color: #888;
    padding-top: 5px;
}

.new-note:last-child {
    margin-bottom: -15px;
}

.stream-date strong {
    background-color: #fff;
    padding: 23px 10px;
    border-radius: 3px;
    color: #707478;
    position: relative;
}

.stream-date-group {
    width: 100%;
    padding-left: 105px;
    padding-right: 10px;
}

.stream-user-info {
    width: 100%;
    /*padding-left: 75px;*/
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.stream-user-box {
    text-align: left;
    width: 40px;
    margin-right: 10px;
}

span.stream-user-details {
    float: left;
}

.stream-author {
    color: #444;
}

/*.stream-user-name {
    font-size: 1.2em;
    margin: 0;
    font-weight: 600;
}*/

.stream-note {
    width: 100%;
    /*padding-left: 75px;*/
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.stream-note__description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.stream-time-header {
    display: inline-block;
    float: right;
}

.stream-icon-container {
    float: right;
}

.stream-time-body {
    float: left;
}

.panel-default>.panel-heading {
    overflow: auto;
}

.stream-date-group-lead {
    padding: 0;
    /*border-top: 2px solid black;*/
    margin-bottom: -1px;
    padding-bottom: 15px;
}

.stream-date-lead {
    margin-bottom: 6px;
}

.stream-date-lead {
    border-radius: 3px;
    color: #242a30;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 9px 0;
}

.stream-date-group-task {
    width: 100%;
    padding-left: 105px;
    padding-right: 10px;
}

.task-details span {
    margin-right: 10px;
}

.stream-date-task strong {
    padding: 23px 21px;
    background: #fff;
    position: relative;
    color: #707478;
}

.activity-inner-row-task {
    border: 1px solid #dddddd;
    margin: 0px 10px 15px 10px;
    border-radius: 3px;
}

.activity-inner-row-task .panel-heading {
    border-bottom: 1px solid #d6d6d6;
}

.stream-stripe-container {
    position: relative;
    padding: 0px 0px 25px 0px;
}

.stream-stripe {
    position: relative;
    margin: 0 auto;
    display: table;
    background: #f0f3f4;
    padding: 0 15px;
}

.stream-stripe-container hr {
    margin: 0;
    margin-top: 7px;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 25px;
    margin-bottom: 0;
    border-color: #d6d6d6;
}

.stream-stripe-container hr {
    margin: 0;
    margin-top: 7px;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 20px;
    margin-bottom: 0;
    border-color: #d6d6d6;
}

h5.stream-date {
    margin: 0;
}

.full-stream-stripe-container hr {
    display: none;
}

.full-stream-stripe-container {
    margin-top: 10px;
    margin-bottom: 25px;
}

.activity-contact-item {
    display: block;
    margin-bottom: 5px;
}

.activity-contact-container {
    margin-bottom: 15px;
}

.stream-date {
    font-size: 18px;
}

.timeline:before {
    display: none;
}

.stream-user-info {
    padding-left: 0;
}

.stream-date-group {
    padding: 0;
    /*margin-bottom: -1px;*/
    padding: 0px;
    padding-bottom: 15px;
}

.stream-date {
    margin-bottom: 6px;
}

.stream-note {
    padding-left: 0;
}

.stream-date strong {
    position: relative;
}

.stream-time-body {
    display: none;
}

.dropdown-header-container--stream {
    margin: 12px 0
}

.full-stream-stripe-container {
    position: relative;
    padding: 0px;
    margin-bottom: 25px;
    margin-top: 0;
}

.full-stream-stripe {
    position: relative;
    margin: 0 auto;
    display: table;
    background: #f0f3f4;
    padding: 0 15px;
}

.full-stream-stripe-container hr {
    display: initial;
    margin: 0;
    margin-top: 8px;
    position: absolute;
    left: 0;
    right: 0;
    margin-bottom: 0;
    border-color: #d6d6d6;
}

.panel-title__stream-icon-group {
    float: left;
}

.activity-form-overflow {
    overflow-y: auto;
    overflow-x:hidden;
    height: calc(100vh - 138px)
}

.activity-panel{
    max-height:600px;
    overflow-y:auto;
    overflow-x:hidden;
}

#fc_frame,
#fc_frame.fc-widget-normal {
  bottom:40px !important;
}

@media only screen and (max-width: 767px) {

    .stream-date {
        font-size: 14px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 415px) {

    .stream-time-header {
        float: none;
        margin-top: 10px;
    }

    .panel-title__stream-icon-group {
        float: none;
        display: block;
    }
}

.panel-blank {
    text-align: center;
    padding: 15px 0px;
}

/********** end stream **********/