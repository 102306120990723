/*********** start milestones *************/

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    background: #e2e4e6;
    border-radius: 3px;
    border: none;
    padding: 7px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ui-sortable {
  list-style: none;
  overflow: auto;
}

.sortable-outer {
    overflow: auto;
}

ul#sortable {
    padding: 0;
}

.milestone-name {
  margin-top: 20px;
  margin-bottom: 5px;
  background-color: #fff;
  border: none;
  font-size: 1.4em;
  border: 1px solid #fff;
}

.milestone-name:focus {
  border-color: #9fa2a5;
}

input.task-name {
  border-left: none;
  padding-left: 45px;
}

.sortable-inner {
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 3px;
  position: relative;
}

.sortable-container {
    padding-right: 432px;
}

.sortable-task {
  padding: 0;
}

.inner {
    padding: 0;
}

.task-placeholder {
    background: #fff;
}

.sortable-inner-placeholder {
  background-color: #8c8c8c;
  height: 34px;
  width: 100%;
  border-radius: 3px;
}

.sortable-outer-placeholder {
  background-color: #8c8c8c;
  height: 100px;
  width: 100%;
  border-radius: 3px;
}

.action-plan-sort-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 1.2em;
  color: #fff;
  background-color: #00acac;
  padding: 10px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.sort-delete {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
}

a.btn.btn-danger.btn.sort-delete {
  color: #fff;
}


a.btn.btn-danger.milestone-delete {
    background: transparent;
    border: none;
    font-size: 1.4em;
    color: #ff5b57;
    float: right;
    padding: 2px 7px;
    margin-bottom: 7px;
    margin-right: 4px;
}

a.btn.btn-danger.milestone-delete:hover {
    color: #cc4946;
}

a.btn.btn-danger.milestone-delete:focus {
    color: #cc4946;
    background: transparent;
    border: none;
}

.task-on-day {
    display: inline;
    width: 75px;
    position: absolute;
    top: 0;
    right: 45px;
}

.se-milestone-visible {
    float: left;
    margin-top: 6px;
}

.task-assign {
    width: 105px;
    position: absolute;
    right: 129px;
    top: 0;
    -webkit-appearance: none;
}

.task-email {
    position: absolute;
    width: 180px;
    position: absolute;
    right: 243px;
    top: 0;
    -webkit-appearance: none;
}

input.form-control.task-on-day {
    padding-right: 3px;
}

.sort-labels {
    position: relative;
}

.sort-label {
    font-weight: 700;
}

.sort-name {
    padding-right: 374px;
}

.sort-subject {
    position: absolute;
    top: 0;
    right: 306px;
}
.sort-assign-to {
    position: absolute;
    top: 0;
    right: 168px;
}

.sort-on-day {
    position: absolute;
    top: 0;
    right: 71px;
}

.sort-name--edit {
    padding-left: 0px;
}

.sort-subject--edit {
    right: 290px
}

.sort-assign-to--edit {
    right: 156px
}

.sort-on-day--edit {
    right: 42px
}

@media only screen and (max-width: 500px) {

    .sortable-container {
        padding-right: 0;
    }

    .task-assign {
        width: 49%;
        margin-left: 1%;
        position: static;
        float: right;
    }

    .task-on-day {
        width: 49%;
        margin-right: 1%;
        position: static;
    }

    .sortable-container {
        padding-right: 42px;
        padding-bottom: 5px;
    }

    .sortable-inner {
        margin-top: 15px;
    }

}

/***** end milestones ******/

/******* start lead action plan *************/

ul.milestone-list, ul.task-lists {
    padding: 0;
}

ul.task-lists {
    margin-top: 7px;
}

.lead-milestone-visible {
    float: right;
    display: inline;
    margin-right: 6px;
}

.action-plan-title {
    display: inline;
}

.milestone-visible {
    float: right;
    padding: 5px 0;
}

.lead-task-container {
    overflow: auto;
    margin: 0px 0px 6px 0px;
    position: relative;
}

.lead-task-name-container {
    padding-right: 415px;
    width: 100%;
    position: absolute;
    z-index: 0;
}

.task-email--non-sortable {
    right: 226px;
}

.lead-task-name, .lead-task-date, .lead-task-assign {
    display: inline;
}

.lead-task-name {
    width: 100%;
}

.lead-task-date {
    float: right;
    width: 105px;
    position: relative;
    z-index: 2;
    margin-right: 7px;
}

.lead-task-assign {
    float: right;
    width: 105px;
    position: relative;
    z-index: 2;
}

.lead-milestone-container {
    overflow: auto;
}

.lead-milestone-top {
    /*overflow: auto;*/
}

.task-complete {
    width: 102px;
    position: relative;
    float: left;
    padding: 6px;
    z-index: 2;
}

.lead-show-complete {
    padding-left: 0px;
}



@media only screen and (max-width: 500px) {
    .lead-task-name-container {
        position: relative;
        display: static;
        padding: 0;
        padding-bottom: 5px;
    }

    .lead-task-date {
        float: none;
        width: 49%;
        margin-right: 1%;
    }

    .lead-task-assign {
        float: right;
        width: 49%;
        margin-left: 1%;
    }

    .lead-task-container {
        margin: 10px 0px 6px 0px;
    }

    .lead-milestone-top {
        overflow: auto;
    }
}

.message {
    min-height: 35px;
}

.panel-body .slimScrollDiv {
    margin-right: -9px !important;
    padding-right: 16px !important;
}

.slimScrollBar, .slimScrollRail {
    width: 10px !important;
    right: 0 !important;
    z-index: 3 !important;
}

.select-box {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.date-range {
    width: 205px;
    margin-left: 23px;
}

.date-range input {
    height: 30px;
}

.action-plan-title-table {
    display: block;
}