.el-cascader {
    width: 100%;
}

/*TODO: should overwrite all el icons to use fontawesome*/
.el-icon-caret-bottom {
    
    &:before {
        content: "\f0d7" !important;
        font-family: FontAwesome !important;
    }
}

.el-cascader-menu__item--extensible {
    
    &:after {
        content: "\f054" !important;
        font-family: FontAwesome !important;
    }
}