.list-group {
    box-shadow: $panel-shadow;
    border-radius: $base-radius;

    &.list-group--select {
        .list-group-item {
            padding: 0;
        }
    }

    .el-checkbox {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 10px 15px;
    }

    &.list-group--no-border,
    &.list-group--cascade {
        box-shadow: none;
        margin-bottom: 0;
    }

    &.list-group--cascade {
        .list-group-item + .list-group-item {
            border-top: 0;
        }

        .list-group-item {
            cursor: pointer;
        }

        .list-group-item:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .list-group-item:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .list-group-item.active, 
        .list-group-item.active:focus, 
        .list-group-item.active:hover {
            background: #edf5fc;
            color: #348fe2;
        }
    }
}

.list-group--border {
    .list-group {
        box-shadow: none;
        border: 1px solid $input-border-color;
    }
}

.list-group-item {
    border: 0;

    + .list-group-item {
        border-top: 1px solid $table-border-color;
    }
}

.list-group--scroll {
    height: auto;
    overflow-x: hidden;
    max-height: 300px;
}
