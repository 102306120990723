// Wrap your whole page with this
.extensibility-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.extensibility-header {
    flex: 0 1 auto;
}

// Use this for the panel class
.extensibility-body {
    overflow: auto;
    margin: 15px;
    height: 100%;
}

// flex: 1 1 auto;
// display: flex;
// flex-flow: row;
// min-width: min-content;

// Use this for the panel body-class
.extensibility-panel-body {
    height: min-content;
    min-width: 65%;
    margin-bottom: 10px;
}

.extensibility-list-page-panel {
    height: min-content;
}

.extensibility-panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    width: fit-content;
    min-width: 990px;
}

.extensibility-footer {
    background: white;
    border-top: 1px solid rgb(217, 217, 217);
    flex: 0 0 75px;

    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}
