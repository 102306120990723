.popover {
    border-radius: 3px;
    padding: 0;
    z-index: 1060;
    border: 1px solid #ddd;
    width: 290px;
    max-width: 290px;
}

.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    border-radius: 3px 3px 0 0;
}

.popover.top >.arrow {
    border-top-color: #ddd;
}

.popover-content__scrollable {
    height: auto;
    overflow-x: hidden;
    max-height: 300px;
    text-align: left;
    margin: 0 -15px;
    padding: 0px 15px;
}

.el-popover--width-auto {
    min-width: auto;
}

/*.el-popover--slide {
    -webkit-transition: width 150ms, height 150ms, -webkit-transform 150ms;
    transition: width 150ms, height 150ms, transform 150ms;
}*/
