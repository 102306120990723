#flow-users-box {
    list-style-type:none;
    padding-left:0;
}

.media-flow__container {
    margin-top: 10px;
}

#flow-users-box h3 {
    margin:5px;
}

#flow-users-box li {
    cursor:move;
}

.flow-user-item {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    margin: 7px 1px;
    padding:3px;
    overflow: hidden;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 3px;
}

.flow-label {
    margin: 8px 5px;
    padding: 5PX;
    float: left;
}

.flow-remove {
    margin: 3px 2px;
}

.flow-user-item:first-child {
    margin-top: 2px;
}

.flow-user-item:last-child {
    margin-bottom: 2px;
}