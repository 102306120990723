.note {
  border-left: 0;
}

.private-checkbox-tooltip {
  max-width: 250px;
}

.private-checkbox-tooltip-icon {
  font-size: 14px;
  margin-left: 6px;
  color: #707273;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 1px;
}
