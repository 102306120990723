.el-checkbox__input {
    &.is-checked,
    &.is-indeterminate {
        .el-checkbox__inner {
            background: $primary;
            border-color: $primary;
        }
    }
}

.el-checkbox--wrap-label {
    display: flex;
    white-space: normal;

    .el-checkbox__label {
        padding-left: 8px;
    }
}

.el-checkbox__inner {
    border-radius: $base-radius;
}

.el-checkbox__label {
    font-size: 13px;

    .help-block {
        padding-left: 27px;
        margin: 0;
    }
}

.el-checkbox-block {
    .el-checkbox {
        display: block;
        margin-bottom: 10px;
    }

    .el-checkbox+.el-checkbox {
        margin-left: 0;
    }
}

.el-checkbox-group,
.el-radio-group {
    label {
        margin-bottom: 0;

        .el-radio__label {
            .el-radio__subtext {
                display: block;
                margin-left: 24px;
                line-height: 16px;
                white-space: normal;
            }
        }
    }
}