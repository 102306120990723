.loader {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: $primary-ultra-light;
    margin: .5rem 0 1rem 0;
    overflow: hidden;

    .indeterminate {
        background-color: $primary;

        &:before {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }

        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
        }
    }
}

.loader--panel {
    border-radius: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
}

.infinite-loading-container {
    .loading-default {
        display: inline-block !important;
        margin: 15px 0 !important;
        height: 30px !important;
        width: 30px !important;
        border: 2px solid #fff !important;
        border-top: 2px solid #24B498 !important;
        border-radius: 100% !important;
        -webkit-animation: rotation .6s infinite linear !important;
        -moz-animation: rotation .6s infinite linear !important;
        -o-animation: rotation .6s infinite linear !important;
        animation: rotation .6s infinite linear !important;

        &:before {
            display: none !important;
        }
    }
}

.spinner-overlay {
    min-height: 200px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    opacity: .9;
    filter: alpha(opacity=90);
    animation: fadeIn .2s;
    -webkit-animation: fadeIn .2s;
    z-index: 1020;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.spinner-container-top-padding {
    padding-top: 100%;
}

@keyframes indeterminate {
    0% {
      left: -35%;
      right:100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
}