.page-body-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
}

.page-form-wrapper {
    padding: 30px;
    margin-top: 30px;
    flex-grow: 0;
}

.page-header-container {
    height: 100px;
    padding-right: 24px;
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-header-title-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.page-header-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.page-header-btn-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 11px 12px;
    gap: 4px;
}

.page-header-btn {
    width: 109px;
    height: 40px;
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
}