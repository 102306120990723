.el-drawer__header {
    padding: 15px;
    border-bottom: 1px solid $panel-border-color;
    margin-bottom: 0;
}

.el-drawer__header > :first-child {
    font-size: 24px;
    font-weight: 300;
    color: $text-black;
}