.te-eddm-map > .ember-view, .eddm-map {
    height: 100%;
}

.te-eddm-map {
    max-height: 100%;
    /*header + ribbon = 98*/
    height: calc(100vh - 54px);
    position: relative;
}

.g-map {
  width: 100%;
  height: calc(100% - 60px);
  padding-right: 325px;
  position: absolute;
}

.g-map-canvas {
  width: 100%;
  height: 100%;
}

.eddm-map__navbar {
    background-color: #f5f5f5;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    border-bottom: 1px solid #d6d6d6;
}

.eddm-map__info {
    font-size: 16px;
    line-height: 1.6;
    width: 325px;
    height: 100%;
    background-color: #fff;
    position: relative;
    float: right;
    padding: 25px;
    z-index: 1;
    -webkit-box-shadow: -4px 0 2px -1px rgba(0,0,0,.22);
    box-shadow: -4px 0 2px -1px rgba(0,0,0,.22);
    height: calc(100% - 60px);
    overflow-y: auto;
}

.eddm-map__navbar-item {
    float: left;
    margin-right: 20px;
}

.dropdown--eddm-map {
    display: inline-block;
    width: 100px;
    margin: 13px 0 13px 10px;
}

.eddm-map__info-group {
    margin-bottom: 20px;
}

.eddm-map__info-group-key {
    width: 135px;
    display: inline-block;
}

.input-group--eddm-search {
    max-width: 400px;
    min-width: 250px;
    padding: 13px 0;
}

.eddm-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  display: none;
}

.eddm-loader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #2980b9;
  animation: eddm-loading 2s linear infinite;
}

@keyframes eddm-loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}

#data-wait {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin: auto;
    vertical-align: middle;
}

#bowl_ring {
    position: absolute;
    left: 3px;
    top: 3px;
    width: 14px;
    height: 14px;
    border: 2px solid rgb(255,255,255);
    border-radius: 14px;
    -o-border-radius: 14px;
    -ms-border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
}

.ball_holder {
    position: absolute;
    width: 11px;
    height: 11px;
    left: 0;
    top: 0px;
    animation-name: ball_move;
    -o-animation-name: ball_move;
    -ms-animation-name: ball_move;
    -webkit-animation-name: ball_move;
    -moz-animation-name: ball_move;
    animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    -ms-animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
}

.ball {
    position: absolute;
    left: 0px;
    top: -3px;
    width: 6px;
    height: 6px;
    background: rgb(255,255,255);
    border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
}

@keyframes ball_move {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes ball_move {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
    }
}

@-webkit-keyframes ball_move {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes ball_move {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

.eddm-map__submit {
  margin-top: 25px;
}

.eddm-map__info-group-key {
    width: 160px !important;
}