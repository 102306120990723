.filter-rule-type-container {
    cursor: pointer;
    transition: max-height 0.3s, padding-top 0.3s, padding-bottom 0.3s;
    overflow: hidden;
}

.filter-rule-type-container-hidden,
.fv-outcome-collapse-item-hidden {
    max-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.filter-rule-type-container-visible {
    max-height: 1000px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fv-collapse-header-container {
    background-color: #F8F8F8;
    transition: max-height 0.3s;
    overflow: hidden;
}

.el-drawer__body {
    .filter-rule-list-form-container {
        overflow-y: auto;
        height: calc(100vh - 83px);
    }
}

/* hide the arrow icon from the collapsables elements on the Focused View rule list */
.fv-rule-type-category-container .el-collapse-item__header__arrow.el-icon-arrow-right,
.fv-collapse-header-container .el-collapse-item__header__arrow.el-icon-arrow-right {
    display: none;
}

.fv-rule-list, .fv-filter-panel {
    width: 100%;
    overflow: auto;
    max-height: calc(100% - 100px);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 150px 0;
    margin: 100px 0px 0px 0px;
}
