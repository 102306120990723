.lm-dialog__footer {
    padding: 0 15px;
    border: none;
}

.el-dialog__header .lm-dialog__header {
    padding: 5px 15px;
}

.lm-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.lead-merge-body .vuetable {
    margin-bottom: 0;
}

.lead-merge-body .vuetable thead {
    display: none;
}

.lm-acknowledge,
.lead-merge-body .vuetable .vuetable-body td,
.lead-merge-confirm {
    font-family: 'Source Sans Pro', sans-serif;
}

.lead-merge-body .vuetable .vuetable-body td {
    font-size: 13px;
    vertical-align: top !important;
    border-top: none;
    padding: 13px 15px !important;
}

.lead-merge-confirm {
    white-space: pre-line;
    width: auto;
}

.lead-merge-confirm .el-message-box__message p {
    font-size: 18px;
    line-height: 24px;
}

.lead-merge-confirm .el-message-box__btns {
    border-top: solid 1px #eee;
}

.lm-body-text,
.lm-review--bottom-panel .el-checkbox__label,
.lm-row-body,
.lm-row-title--bold,
.lm-flex i {
    color: #000;
}

.lm-review--bottom-panel .el-form-item__error {
    left: 25px;
}

.lm-row-title {
    color: #626262;
    font-weight: 300;
    font-size: 12px;
    margin: 0;
    line-height: 16px;
}

.lm-row-title--bold {
    font-weight: 800;
    line-height: 19px;
}

.lm-row-body {
    font-weight: 500;
    line-height: 16px;
}

.lm-row-body--whitespace {
    white-space: pre-line;
}

.lm-row-link {
    font-weight: 500;
    line-height: 16px;
    color: #1A73E8;
    margin: 5px 0 0 0;
    display: block;
}

.lm-review--top-panel {
    padding: 10px 5px;
}

.lm-review--bottom-panel {
    padding: 25px 50px 25px 20px;
}

.lm-flex {
    display: flex;
}

.lm-review__name {
    padding-left: 0 !important;
}

.lm-flex i {
    font-size: 16px;
    margin: 0 10px 0 0;
}

.lm-acknowledge {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}