.el-color-dropdown,
.el-picker-panel {
    z-index: 20000 !important;
}

.el-picker-panel {
    border: 0;

    .popper__arrow {
        display: none;
    }
}

.el-picker-panel__shortcut {
    font-size: 12px;
    margin-left: 5px;
}

.el-date-table {
    td.available:not(.in-range) {
        &:hover {
            span {
                background: #F2F6FC;
            }
        }
    }
}

.el-date-editor .el-range-separator {
    line-height: 22px;
}

.el-date-table td {
    font-size: 11px;
}

.el-date-editor {
    .el-input__prefix,
    .el-input__suffix {
        width: 28px;
    }
}

.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
    font-size: 12px;
}

.panel-heading-right {
    .el-date-editor--daterange.el-input {
        width: 190px !important;
    }
}


/*bootstrap datepikcer*/

.datepicker {
    z-index:1151 !important;
}

