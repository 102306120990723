.session-logout-timer {
    position: fixed;
    height: 55px;
    padding: 10px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    z-index: 9999;
}

.session-logout-timer__time {
    display: inline-block;
    color: $danger;
    margin: 0 5px 0 0;
    overflow: hidden;
}

.session-logout-timer__text {
    display: inline-block;
}