.record {
    border: 1px solid #ccc;
    margin: 6px 0;
    padding: 4px;
}

.record h4 {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
}

.record button.expand {
    margin-top: 6px;
}

.record .created_on {
    margin-top: 11px;
}

.record.collapsed .body {
    display: none;
}

.record .user-image {
    border-radius: 30px;
    float: left;
    height: 30px;
    margin: -5px 10px 0 0;
    width: 30px;
}

.record img.user {
    border-radius: 30px;
    float: left;
    height: 30px;
    margin: -5px 10px 0 0;
    width: 30px;
}

.record .body {
    margin: 12px 0;
}

.record .body hr {
    margin-top: 0;
    margin-bottom: 0;
}

.record.completed {
    border-color: #00acac;
    background-color: #daf1f1;
}

.record.overdue {
    border-color: #f99;
    background-color: #fee;
}

.no-records-message {
    margin-top: 8em;
}

.record h5 {
    font-size: 1.2em;
    font-weight: bold;
    margin-left: 0.5em;
}

.row > .wide {
    padding: 0 2px 0;
}

.row > div > .pagination {
    margin: 0;
}

#graph {
     width: 393px;
     height: 198px;
}
