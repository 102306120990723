/* Jquery Tagit
================================================== */

.ui-menu .ui-menu-item {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    padding: 5px 25px;
    text-align: left;
    font-size: 1.1em;
}

.ui-menu .ui-menu-item:hover {
    background: #edf0f5;
    text-decoration: 0;
    border: 0;

    .ui-state-active {
        background: #edf0f5;
        text-decoration: 0;
        border: 0;
    }
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    border: 0;
    background: inherit;
    font-weight: inherit;
    color: inherit;
}

.tagit-choice.ui-widget-content.ui-state-default.ui-corner-all.tagit-choice-editable {
    display: block;
    float: left;
    margin: 2px 5px 2px 0;
    position: relative;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    background: #edf0f5;
    border: 0 !important;
    border-radius: 0;
    margin: 0 !important;
}

.ui-autocomplete {
    border: 0 !important;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0,0,0,0.25) !important;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.25) !important;
    z-index: 5000;
    max-height: 200px;
    overflow: auto;
    max-width: 300px;
}

.modal .ui-autocomplete.ui-widget-content {
    background: 0;
}

.tagit-org ul.tagit li.tagit-choice-editable {
    background: #49b6d6 !important;
}

.tagit-user ul.tagit li.tagit-choice-editable {
    background: #727cb6 !important;
}

.tagit-close .text-icon {
    display: none;
}

.tagit-close .ui-icon-close {
    position: absolute;
    right: 5px;
    top: 0px;
}