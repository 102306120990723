/* VARIABLES */
$modal-title-font-size: 24px;
$modal-header-padding: 15px;
$modal-header-border-bottom-size: 1px;
$scroll-modal-margin: 30px;

$dialog-header-height: (2 * $modal-header-padding) + $modal-title-font-size + $modal-header-border-bottom-size;
$dialog-footer-height: 65px;
$scroll-container-offset: $dialog-header-height + (2 * $scroll-modal-margin);

.el-dialog {
    width: calc(100% - 30px);
}

.el-dialog--scroll {
    overflow: hidden;
    width: 100%;
}

.el-dialog--scroll .el-dialog {
    top: $scroll-modal-margin !important;
    bottom: $scroll-modal-margin;
    margin-bottom: 0;

    .el-dialog-body__scroll-container {
        height: calc(100vh - #{$scroll-container-offset});
        overflow: auto;
    }

    .top-ribbon + .el-dialog-body__scroll-container {
        height: calc(100vh - #{$scroll-container-offset + $top-ribbon-height});
    }

    .el-dialog-body__scroll--footer {
        height: calc(100vh - #{$scroll-container-offset + $dialog-footer-height });
        overflow: auto;
    }

    .top-ribbon + .el-dialog-body__scroll--footer {
        height: calc(100vh - #{$scroll-container-offset + $top-ribbon-height + $dialog-footer-height });
    }
}

.el-dialog__header {
    padding: $modal-header-padding;
    border-bottom: $modal-header-border-bottom-size solid $panel-border-color;
    display: flex;
    align-content: center;
}

.el-dialog__title,
.modal-title {
    font-size: $modal-title-font-size;
    font-weight: 300;
    color: $text-black;
}

.el-dialog__headerbtn {
    margin-left: auto;
}

.el-dialog__body {
    padding: 15px;
    color: inherit;
    font-size: inherit;
}

.el-dialog--scroll-body {
    .el-dialog {
        top: 30px !important;
    }

    .el-dialog__body {
        max-height: calc(100vh - 200px);
        overflow: auto;
    }
}

.el-dialog__footer {
    padding: 15px;
    border-top: 1px solid $panel-border-color;
}

.el-dialog--bg-silver {
    .el-dialog__body {
        background-color: $global-body-background-color;
    }
}

.no-body-padding {
    .el-dialog__body {
        padding: 0;
    }
}

@include media-md {
    .el-dialog {
        width: 600px;
    }

    .el-dialog--tiny {
        width: 300px;
    }
}

@include media-lg {
    .el-dialog--large {
        width: 900px;
    }
}

@include media-xl {
    .el-dialog--xl {
        width: 1100px;
    }
}

.el-dialog--medium-large {
    width: 1000px;
}

.el-dialog--full {
    width: calc(100% - 60px);
    top: 30px;
    margin-bottom: 30px;
    height: auto;
    overflow: hidden;
}

.el-dialog--fullscreen {
    .el-dialog__header {
        background: $sidebar-color;
    }

    .el-dialog__title {
        color: #fff;
    }

    .el-dialog__body {
        height: calc(100vh - 55px);
    }

    .el-dialog__headerbtn {
        .el-dialog__close {
            color: #fff;

            &:hover {
                color: rgb(191, 201, 217);
            }
        }
    }

    .el-dialog {
        top: 0 !important;
        bottom: 0;
        width: 100%;
        margin-bottom: 0;
    }

    &.el-dialog--scroll-body {
        .el-dialog__body {
            max-height: inherit;
            height: calc(100vh - 55px);
            overflow: auto;
        }
    }

    .el-dialog--full {
        height: 100%;
        margin-bottom: 0px;
    }
}

.el-dialog-scroll--fullscreen {
    .el-dialog__header {
        background: $sidebar-color;
    }

    .el-dialog__title {
        color: #fff;
    }

    .el-dialog__body {
        height: calc(100% - 120px);
    }

    .el-dialog__headerbtn {
        .el-dialog__close {
            color: #fff;

            &:hover {
                color: rgb(191, 201, 217);
            }
        }
    }

    .top-ribbon ~ .el-dialog-body__scroll--footer {
        height: calc(100vh - 165px) !important;
    }

    .el-dialog-body__scroll--footer {
        height: calc(100vh - 121px) !important;
        overflow: auto;
    }

    .el-dialog-body__scroll--top-ribbon {
        height: calc(100vh - 148px) !important;
        overflow: auto
    }

    .el-dialog-body__scroll-container {
        height: calc(100vh - 108px);
        overflow: auto;
    }

    .el-dialog {
        top: 0 !important;
        bottom: 0;
        width: 100%;
        margin-bottom: 0;
    }

    &.el-dialog--scroll-body {
        .el-dialog__body {
            max-height: inherit;
            height: calc(100vh - 55px);
            overflow: auto;
        }
    }

    .el-dialog--full {
        height: 100%;
        margin-bottom: 0px;
    }
}

.el-dialog--grey-bg {
    .el-dialog__body {
        background: #e8e8e8;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

/*BOOTSTRAP MODAL*/

.modal {
    z-index: 1070;
}

.modal-content > .alert {
    border-radius: 0;
}

.modal-header .close {
    font-size: 32px;
    font-weight: 300;
}

.close:focus, .close:hover {
    color: $primary;
    text-decoration: none;
    opacity: 1;
    outline: none;
}

.modal-scroll {
    height: 100%;
    width: 100%;
    overflow: scroll;
    max-height: 550px;
}

.modal-backdrop {
    z-index: 1040;
}

.modal-dialog--m100 {
    margin: 100px 10px;
}

@media screen and (min-width: 768px) {
    .modal-dialog--m100 {
        margin: 100px auto;
    }
}
