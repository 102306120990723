.security-settings-header {
    flex: 0 1 auto;
    overflow: hidden;
}

.el-tabs__header {
    padding-left: 20px;
    margin-bottom: 0;
}

.security-settings-tab-pane {
    height: calc(100vh - 200px);
    overflow: hidden;
}

.qr-code-display {
    padding: 20px;
    width: 400px;
}

.verify-code-help-text {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
}

.verify-code-input {
    font-family: sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.muted-help-text {
    margin:4px 0;
    padding: 0;
    color: #afaeae;
    line-height: 1.5;
    font-weight: 400;
}

.muted-help-text .bold {
    margin:4px 0;
    padding: 0;
    color: #9a9999;
    line-height: 1.5;
    font-weight: 600;
}

.bold-help-text {
    font-weight: 600;
    margin: 4px 0;
    padding: 0;
    color: #333333;
    line-height: 1.5;
}

.help-text {
    font-weight: 400;
    color: #333333;
    margin: 4px 0;
    padding: 0;
    line-height: 1.5;
}

.mfa-setup-dialog-spacer {
    width: 100%;
    height: 200px;
}

.mfa-app-link {
    display: block;
    margin: 6px 0;
}

.mfa-apps-links {
    margin: 20px 0 0;
    height: 100px;
}

.qr-code {
    height: 150px;
    width: auto;
    margin: 20px 0;
}

.mfa-message {
    z-index: 1000;
}

.security-settings-tab-pane .panel {
    width: 50%;
    padding: 10px
}
