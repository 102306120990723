.el-collapse--border-top {
    border: 0;
    border-top: 1px solid rgb(223, 229, 236);
}

.el-collapse-item--no-padding {
    .el-collapse-item__content {
        padding: 0;
    }
}
