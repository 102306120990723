.social-post__header {
    padding-bottom: 10px;
}

.social-post__logo {
    img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
}

.social-post__title {
    font-weight: 600;
    color: $text-black;
}

.social-post__title-logo {
    height: 20px;
    width: auto;
}

.social-post__header-group {
    padding-left: 7px;
    width: calc(100% - 56px);
}

.social-post__body {
    margin-left: 0;
    word-wrap: break-word;
    width: 100%;
    height: auto;
    white-space: normal;
    box-sizing: border-box;
}

.social-post__body-image {
    padding-top: 10px;

    img {
        max-width: 600px;
        width: 100%;
        height: auto;
        border-radius: $base-radius;
    }
}

.social-post__date-prefix {
    display: none;
}

@include media-sm {
    .social-post__body {
        padding-left: 42px;
    }

    .social-post__date-prefix {
        display: inline-block;
    }
}
    
