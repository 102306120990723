/* Lead View Bomb Bomb
================================================== */

.bb-video-recorder-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 108px;
    position: relative;
}

.recorder-frame {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-preview {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.bb-title {
    font-weight: 600;
}

.bb-info {
    padding: 10px 0 20px 0;
}

.bb-scroll-container {
    padding: 0 10px;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {

    .video-email-btn {
        margin-bottom: 10px;
    }
}