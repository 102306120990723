.tile-col {
    padding: 0 10px;
    padding-bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}

.tile-logo {
    width: auto;
    height: 50px;
    margin: 0 auto;
    display: table;
    padding: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 2.7em;
}

.tile-container {
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    cursor: pointer;
    max-height: 100px;
}

.tile-container:hover {
    border-color: #0193d0;
}

.tile-container-lg {
    max-height: 100%;
}

.tile-container-tall {
    max-height: 125px;
    height: 125px;
}

.tile-container:hover {
    border-color: #0193d0;
}

.tile-btn {
    margin: 0;
    text-align: center;
    padding: 5px 0px;
    color: #242a30;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0;
    margin-bottom: 10px;
}

a.tile-link {
    text-decoration: none;
    color: #348fe2;
}

.tile-overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tile-overlay h3 {
    color: #fff;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
}

.panel--tile {

    .panel-body {
        padding: 25px 15px;
        min-height: 108px;
    }
}
    
.tile-col {
    padding: 0 10px;
    padding-bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}

.tile-logo {
    width: auto;
    height: 50px;
    margin: 0 auto;
    display: table;
    padding: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 2.7em;
}

.tile-container {
    border: 1px solid $table-border-color;
    border-radius: 3px;
    transition: all 0.2s ease-out;
    cursor: pointer;
    max-height: 100px;

    &.is-active {
        border-color: $primary;
    }

    &:hover {
        border-color: $primary;
    }
}

.tile-container:hover {
    border-color: #0193d0;
}

.tile-container-lg {
    max-height: 100%;
}

.tile-container-tall {
    max-height: 125px;
    height: 125px;
}

.tile-btn {
    margin: 0;
    text-align: center;
    padding: 5px 0px;
    color: #242a30;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0;
    margin-bottom: 10px;
}

a.tile-link {
    text-decoration: none;
    color: #348fe2;
}

.tile-overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tile-overlay h3 {
    color: #fff;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
}

.tile--white {
    background: #fff;
}

/*Dashboard tiles AKA widget*/

.widget {
    height: 134px;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 15px;
    overflow: hidden;
    border: 2px solid transparent;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
}

.widget-stats .stats-desc, .widget-stats .stats-title {
    color: #fff;
    min-height: 26px;
    font-size: 13px;
}

.widget-stats .stats-number {
    font-size: 3em;
    font-weight: 300;
    margin-bottom: 10px;
}
