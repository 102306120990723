
/* GRADIENT */
$skeleton-shine-gradient: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100% );
$skeleton-shine-gradient-width: 50px;
$skeleton-shine-gradient-bg-size: $skeleton-shine-gradient-width 100%;
$skeleton-shine-gradient-initial-bg-position: -$skeleton-shine-gradient-width 0;
$skeleton-shine-gradient-final-bg-position: calc(100% + #{$skeleton-shine-gradient-width}) 0;

/* CONTENT */
$skeleton-content-lines: 4;
$skeleton-content-gradient-height: 1em;
$skeleton-content-gradient-line-height: 1.2em;
$skeleton-content-gradient-total-height: 4 * $skeleton-content-gradient-line-height;
$skeleton-content-gradient-padding: ($skeleton-content-gradient-line-height - $skeleton-content-gradient-height) / 2;
$skeleton-content-gradient: linear-gradient( lightgray $skeleton-content-gradient-height, transparent 0 );

/* TITLE */
$skeleton-title-gradient-padding: 5px;
$skeleton-title-gradient: linear-gradient( lightgray 100%, transparent 0 );
$skeleton-title-gradient-bg-position: 0 0;

/* AVATAR */
$skeleton-avatar-gradient: radial-gradient( circle closest-side at center, lightgray 99%, transparent 0 );
$skeleton-avatar-gradient-bg-position: 0 0;


@function skeleton-content-gradient-bg-position($line-number) {
    $width: 0;
    $height: $skeleton-content-gradient-padding + ($line-number * $skeleton-content-gradient-line-height);
    @return $width $height;
}

.skeleton-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1001;
    border-radius: 3px;
}

.skeleton-loader-avatar {
    background-image:
        $skeleton-shine-gradient,
        $skeleton-avatar-gradient;

    background-size:
        $skeleton-shine-gradient-bg-size,
        100% 100%;

    background-position:
        $skeleton-shine-gradient-initial-bg-position,
        $skeleton-avatar-gradient-bg-position;

    background-repeat: no-repeat;

    animation: skeleton-loader-shine-avatar 1s infinite;
}

.skeleton-loader-content {
    line-height: $skeleton-content-gradient-line-height;

    background-image:
        $skeleton-shine-gradient,
        $skeleton-content-gradient,
        $skeleton-content-gradient,
        $skeleton-content-gradient,
        $skeleton-content-gradient;

    background-size:
        $skeleton-shine-gradient-bg-size,
        66% $skeleton-content-gradient-total-height,
        100% $skeleton-content-gradient-total-height,
        75% $skeleton-content-gradient-total-height,
        50% $skeleton-content-gradient-total-height;

    background-position:
        $skeleton-shine-gradient-initial-bg-position,
        skeleton-content-gradient-bg-position(0),
        skeleton-content-gradient-bg-position(1),
        skeleton-content-gradient-bg-position(2),
        skeleton-content-gradient-bg-position(3);

    background-repeat: repeat-y;

    animation: skeleton-loader-shine-content 1s infinite;
}

.skeleton-loader-block {
    margin: initial;
}

.skeleton-loader-block,
.skeleton-loader-title {
    background-image:
        $skeleton-shine-gradient,
        $skeleton-title-gradient;

    background-size:
        $skeleton-shine-gradient-bg-size,
        100% 100%; /* title */

    background-position:
        $skeleton-shine-gradient-initial-bg-position,
        $skeleton-title-gradient-bg-position;

    background-repeat: no-repeat;

    animation: skeleton-loader-shine-title 1s infinite;
}

@keyframes skeleton-loader-shine-avatar {
    to {
        background-position:
            $skeleton-shine-gradient-final-bg-position,
            $skeleton-avatar-gradient-bg-position;
    }
}

@keyframes skeleton-loader-shine-content {
    to {
        background-position:
            $skeleton-shine-gradient-final-bg-position,
            skeleton-content-gradient-bg-position(0),
            skeleton-content-gradient-bg-position(1),
            skeleton-content-gradient-bg-position(2),
            skeleton-content-gradient-bg-position(3);
    }
}

@keyframes skeleton-loader-shine-title {
    to {
        background-position:
            $skeleton-shine-gradient-final-bg-position,
            $skeleton-title-gradient-bg-position;
    }
}
