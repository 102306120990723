@import '../variables';

.el-transfer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.el-transfer-panel {
    width: 100%;
}

.el-transfer-panel__header {
    display: none;
}

.el-transfer__buttons {
    padding: 0 15px;
}

.el-transfer-panel__filter {
    margin: 0;
}

.el-transfer-panel__filter .el-input__inner {
    padding: 0 10px;
    border-radius: 0;
    height: 40px;
    font-size: 14px;
}

.el-transfer-panel__body .el-input {
    width: auto;
}

.el-transfer-panel__filter .el-input__prefix {
    left: auto;
    right: 5px;
    width: 20px;
}

.el-transfer-panel__filter .el-input__prefix .el-icon-search {
    color: $primary;
    font-size: 14px;
}

.el-transfer-panel__body {
    background-color: $bg-silver;
}

.el-checkbox.el-transfer-panel__item.is-checked {
    background-color: $primary;
    margin: 0;
    padding-left: 15px;
}

.el-transfer-panel__item.is-checked .el-checkbox__label {
    color: $white;
}

.el-transfer-panel__list.is-filterable {
    padding-top: 5px;
}

.el-transfer-panel__item .el-checkbox__input {
    display: none;
}

.el-transfer-panel__item.el-checkbox .el-checkbox__label {
    padding-left: 0;
}

.el-transfer-wrap {
    margin: 0 15px 0 0;
}