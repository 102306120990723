.ci-popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    box-shadow: inset 3px 0px 0px 0px #48D597;
  }
  .ci-popover.top {
    margin-top: -10px;
  }

  .ci-popover > .arrow,
  .ci-popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .ci-popover > .arrow {
    border-width: 11px;
  }
  .ci-popover > .arrow:after {
    content: "";
    border-width: 10px;
  }
  .ci-popover.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0;
  }
  .ci-popover.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0;
  }

.ci-popover > .popover-title {
    background-color: transparent;
    border-bottom: none;
    border-radius: 0;
    font-weight: 600;
    font-size: 12px;
    color: #425563;
    letter-spacing: 1px;
  }

  .ci-popover > .popover-content {
    font-weight: 400;
    color: #101820;
    padding: 0px 6px 14px 14px;
  }

  .ci-popover-hover {
    color: #0162C0;
    border-bottom: 1px #0162C0 dashed;
  }

  .ci-popover-hover:hover {
    border-bottom: none;
  }
