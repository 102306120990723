.ia-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.ia-header {
    flex: 0 1 auto;
}

.ia-body {
    overflow: auto;
    background: rgb(239, 239, 239);
    margin-bottom: 15px;
    flex: 1 1 auto;

    display: flex;
    flex-flow: row;
    height: 100%;
}

.ia-list-table {
    height: min-content;
}

.ia-stepper {
    margin-left: 5%;
    height: 150px;
    min-width: 300px;
}

.ia-panel-body {
    height: min-content;
    min-width: 65%;
    margin-bottom: 10px;
}

.ia-panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    width: fit-content;
    min-width: 990px;
}

.ia-footer {
    background: white;
    border-top: 1px solid rgb(217, 217, 217);
    flex: 0 0 75px;

    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}
