.tidx_item {
    margin-bottom: 20px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    border-radius: 3px;
    overflow: hidden;
}

.tidx_details {
    background: #fff;
    padding: 15px;
}

.tidx_image {
    position: relative;
    overflow: hidden;
    height: 220px;
}

.tidx_image img {
    /*margin-top: -20px;*/
    margin-top: 0px;
}

.tidx_mls_logo {
    float: right;
}

.tidx_listed_by {
    float: left;
    font-size: 12px;
}

.tidx_by_logo {
    overflow: hidden;
}

.tidx_price {
    font-size: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(60, 63, 79, .9);
    padding: 5px;
    color: #fff;
}

.tidx_top {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
}

.tidx_overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.85);
    border: 2px solid #348fe2;
    cursor: pointer;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    z-index: 2;
}

.tidx_overlay.active {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    border: 2px solid #348fe2;
    cursor: pointer;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    opacity: 1;
}

.tidx_image:hover .tidx_overlay {
    opacity: 1;
}

.tidx_show-select {
    font-size: 60px;
    color: #348fe2;
}

.tidx_result-header {
    text-align: center;
    padding: 5px 0 10px 0;
}

.tidx__top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
}