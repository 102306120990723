.image-select {
    img {
        max-height: 250px; 
        margin: 0 auto;
    }

    .square:hover > img {
        filter:blur(4px);
    }
}

.image-select__placeholder {
    position: relative;

    i {
        display: block;
    }
}

.image-select__placeholder-title {
    line-height: 2;
}

.image-select__bg {
    background: $global-body-background-color;
    border-radius: $base-radius;
    cursor: pointer;
}