.jr-report-builder-panel {
    padding: 10px 0;
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.split-test-report-header {
    display: flex;
    width: 100%;

    .split-test-report-header-item-right {
        margin-left: auto;
    }
}