.page-full-screen {
    &.page-header-fixed {
        padding-top: 0;
    }

    .side-menu.left,
    .side-menu.is-open.left {
        left: 0;
    }

    .side-menu {
        top: 44px !important;
    }

    .top-ribbon {
        top: 0;
        left: 0 !important;
        width: 100% !important;
    }

    .side-menu.is-open.left + * {
        margin-left: 280px;
        position: relative;
    }

    .gjs-editor-cont {
        height: calc(100vh - 44px) !important;
    }

    .gjs-editor-margins {
        .gjs-editor {
            margin: 25px;
            height: calc(100vh - 125px) !important;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
        }
    }
}