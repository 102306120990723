.info-panel.active {
    right: 0;
}

.info-panel {
    position: fixed;
    right: -80%;
    top: 0;
    bottom:0;
    z-index: 1020;
    -webkit-box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.75);
    box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.75);
    width: 50%;
    -webkit-transition: right .2s linear;
    -moz-transition: right .2s linear;
    transition: right .2s linear;
    outline: none;
}

.info-panel-content {
    height: calc(100vh - 61px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 100px;
    padding: 15px;
}

.info-panel-header {
    background: #fff;
    padding: 12px 15px;
    width: 100%;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
    position: relative;
    z-index: 4;
}

.info-panel-title {
    float: left;
    margin: 0;
    line-height: 1.7;
}

.info-panel-content .stream-stripe,
.info-panel-content .full-stream-stripe {
    background: #fff;
}

.info-panel-content .panel:last-child {
    margin-bottom: 60px;
}

@media only screen and (max-width: 1024px) {

    .info-panel {
        width: 70%;
    }
}

@media only screen and (max-width: 415px) {

    .info-panel-panel {
        padding: 0;
    }

    .info-panel {
        right: -100%;
    }

    .info-panel.active {
        width: 100%;
    }
}
