.table-container {

    &.fit-page {
        height: calc(100vh - 98px);
        overflow: auto;
    }
}

.table--pretty,
.data-table,
.no-overflow-y {
    overflow-y: hidden;
}

.no-wrap--head {
    th {
        white-space: nowrap;
    }
}

.ellipsis--multi {
    display: inline-block;
    display: -webkit-inline-box;
    max-width: 400px;
    min-width: 300px;
    max-height: 48px;
    font-size: 12px;
    margin: 0 auto;
    line-height: 1.4;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
}

.vuetable {

    tr {
        transition: background 0.5s linear;
        height: 51px;
    }

    thead {
        background: $white;
    }

    thead>tr>th {
        vertical-align: middle;
    }

    .el-checkbox {
        margin-bottom: 0;
    }

    .sort-icon {
        float: none !important;
        padding-left: 5px;
    }
}

.vuetable-checkboxes {
    padding-left: 15px !important;
}

.dataTables_wrapper tbody>tr>th:first-child,
.dataTables_wrapper thead th:first-child,
.dataTables_wrapper tbody>tr>td:first-child {
    padding-left: 20px;
}

.dataTables_wrapper tbody>tr>th:last-child,
.dataTables_wrapper thead th:last-child,
.dataTables_wrapper tbody>tr>td:last-child {
    padding-right: 20px;
}

.panel-body--no-padding .table,
.floated thead {
    border-top: 1px solid $table-border-color;
}

.vuetable--no-select-all {

    .vuetable-th-checkbox-id {
        visibility: hidden;
    }
}

.vuetable-import {

    tr {
        height: 65px;
    }
}

.panel-body--fit-table {
    padding: 0;
}


.dataTables_scroll {
    border-bottom: 1px solid $table-border-color;
}

.panel-body,
.panel-body--no-padding {

    .data-table {
        border-top: 1px solid $table-border-color;
    }

    .dataTables_wrapper {
        margin: -15px;
    }

    .table-striped>tbody>tr:nth-child(odd)>td, .table-striped>tbody>tr:nth-child(odd)>th {
        background: $white;
    }

    .table-bordered>tbody>tr>td,
    .table-bordered>tbody>tr>th,
    .table-bordered>tfoot>tr>td,
    .table-bordered>tfoot>tr>th,
    .table-bordered>thead>tr>td,
    .table-bordered>thead>tr>th {
        border: 0;
    }

    .table-bordered {
        border: 0;
        border-top: 1px solid $table-border-color;
    }

    td {
        vertical-align: middle !important;
    }

    .table-bordered tr {
        border-top: 1px solid $table-border-color;
    }

    .dataTables_paginate {
        margin: 0 0 -15px 0;
        padding: 15px 15px 15px;

        .pagination {
            margin: 0;
        }
    }

    .dataTables_filter {
        padding: 15px 15px 0;
    }

    .dataTables_length {
        padding-left: 15px;
    }

    .dataTables_scroll {
        margin-bottom: 0;
    }

    .dataTables_info {
        margin: 0 0 -15px 0;
        padding: 25px 0 25px 15px;
    }
}

.dtwrap-header {
    .dataTables_filter {
        padding: 0;

        .dropdown-menu.fixed {
            right: 10px !important;
            margin-right: 32px;

            @media only screen and (max-width: 959px) {
                left: calc(10vw - 10px) !important;
            }

            @media only screen and (min-width: 960px) and (max-width: 1399px) {
                left: calc(20vw - 10px) !important;
            }

            @media only screen and (min-width: 1400px) {
                left: calc(30vw - 10px) !important;
            }
        }
    }
}

.panel-body.p-0 {
    .dataTables_wrapper {
        margin: 0px;
    }
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    padding-left: 10px;
    display: inline-block;
    position: absolute;
    font-family: FontAwesome;
}

table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    color: $primary;
}

.dtwrap-header {
    position: relative;

    .dataTables_filter input {
        height: 34px;
    }
}

.dtwrap-body {

    .dataTables_scroll {
        margin-bottom: 0;
    }
}

.table-footer--pretty {
    position: relative;
}

.dtwrap-footer {
    position: relative;
    min-height: 48px;

    .dataTables_info {
        margin: 15px;
        padding: 0 !important;
    }
}

.filter-tools .dt-buttons {
    margin: 0 5px 0 10px;
}

.dt-advanced .el-checkbox__inner {
    top: 2px;
}

.dt-advanced .selected .el-checkbox__inner {
    background: $primary;
    border-color: $primary;
}

.dt-advanced .selected .el-checkbox__inner::after {
    box-sizing: content-box;
    content: "";
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 8px;
    left: 5px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 4px;
    transition: transform .15s cubic-bezier(.71,-.46,.88,.6) .05s;
    transform-origin: center;
    transform: rotate(45deg) scaleY(1);
}

.dt-advanced .select-checkbox {
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
}

.dataTables_filter input {
    height: auto;
}

.vuetable-row--selected,
.dt-advanced tbody tr.even.selected,
.dt-advanced tbody tr.odd.selected {
    background: $table-selected-row-color;
    color: inherit;
}

.dt-toolbar-title-container {
    display: block;
    float: left;
    margin: 0px 10px 0 15px;
    font-size: 1.8em;
    font-weight: bold;
    padding-left: 25px !important;
}

.dt-toolbar-count-container, .dt-toolbar-perspective-link-container {
    display: block;
    float: left;
    margin: 7px 10px 0 15px;
}

.table-elipsis {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    border: 0 !important;
}

.table-elipsis--lg {
    width: 170px;
}

.table-elipsis--media {
    width: calc(100% - 80px);
    min-height: 20px;
}

.table-elipsis--email-description{
    width: 100%;
}

h5.table-elipsis--media {
    margin-top: 7px;
}

a .table-elipsis--media {
    color: #337ab7;
}

.table-avatar {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 5px;
}

.table-avatar--square {
    height: 35px;
    width: 35px;
    border-radius: 0;
    margin-right: 5px;
}

.table-avatar--lg {
    height: 50px;
    width: auto;
    border-radius: 0;
}

.cell-red {
    box-shadow: 3px 0 0 #ff5b57 inset;
}

.cell-orange {
    box-shadow: 3px 0 0 #f59c1a inset;
}

.cell-green {
    box-shadow: 3px 0 0 #00acac inset;
}

.cell-blue {
    box-shadow: 3px 0 0 #348fe2 inset;
}

.cell-grey {
    box-shadow: 3px 0 0 #b6c2c9 inset;
}

table .btn-group {
    display: flex;
}

table.table--pretty>tbody>tr:last-child {
    border-bottom: 1px solid #e2e7eb;
}

.ribbon-container__dropdown {
    display: inline-block;
}

div.dataTables_scrollBody thead th::after {
    display:none !important;
}

div.dataTables_paginate {
    margin-bottom: 40px;
}

td.contact-info-cell.truncate {
    padding-left: 26px;
}

table.dataTable thead th {
    position: relative;
    background-image: none !important;
}

table.dataTable thead th.sorting:after {
    content: "\f0dc";
    color: #ddd;
    font-size: 1em;
}

table.dataTable thead th.sorting_asc:after {
    content: "\f0de";
}

table.dataTable thead th.sorting_desc:after {
    content: "\f0dd";
}

table.dataTable thead th {
    padding-top: 15px;
    padding-bottom: 15px;
}

.dataTables_processing {
    height: 30px;
    width: 30px;
    position: absolute;
    background-image: url('./images/icons/diamond.svg');
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: none !important;
    -webkit-animation: rotation .8s infinite linear;
    -moz-animation: rotation .8s infinite linear;
    -o-animation: rotation .8s infinite linear;
    animation: rotation .8s infinite linear;
    left: 50%;
    top: 13px;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.panel--full-table .dataTables_paginate {
    margin-right: 95px;
}

.panel--full-table .dataTables_length {
    margin-left: 25px;
}

.panel--full-table .dataTables_filter,
.panel .dataTables_filter {
    text-align: right;
    margin-right: 0px;
}

.panel--full-table .dataTables_filter label {
    margin-right: 15px;
}


div.dataTables_length label {
    margin-bottom: 15px;
}

.panel--full-table .dataTables_paginate > span > span {
    display: none;
}

.table>tfoot>tr>td,
.table>tfoot>tr>th {
    padding: 7px;
    color: #333;
    font-weight: 600;
}

/* New Datatables Styles
================================================== */

.dt-advanced {
    width:100%;
}

.dt-advanced thead th.collapse-column {
    width:1px;
}

.dt-advanced tbody > tr > td {
    white-space: nowrap;
}

.dataTables_info {
    display:block;
    margin-left:10px;
    padding:0;
}

.dataTables_scroll {
    margin-bottom:10px;
}

.action-tools {
    float:left;
}

.action-tools .dt-buttons {
    float:left;
    margin: 0 5px 5px 15px;
}

.filter-tools .dataTables_filter {
    margin-right:5px;
    text-align: right;
 }

 .dtwrap-header .dataTables_filter label {
    text-align:right;
    display: inline-block;
    margin-bottom:0;
    float:none;
}

table.dataTable {
    margin-top: 0!important;
}

.filter-tools .dataTables_filter input {
    margin-right: 0px;
    width: 200px;
    float: right;
}

.filter-tools .dataTables_filter .dt-buttons {
    float:right;
}

.fixed-button-header {
    margin-bottom: 5px;
}

.dt-advanced .data-row {
    -webkit-transition: background 0.5s linear;
    -moz-transition: background 0.5s linear;
    -ms-transition: background 0.5s linear;
    -o-transition: background 0.5s linear;
    transition: background 0.5s linear;

    height: 51px !important;
}

.dt-advanced.no-multi-select th.select-checkbox > span.el-checkbox__inner {
    visibility: hidden;
}

div.dataTables_scrollHead > div > table > thead > tr:nth-child(2) > th.select-checkbox.selected {
    background:none;
}

 .dt-advanced .dataTables_length {
    display:none;
 }

 .dt-advanced thead th.color-cell {
    padding:0 5px 0 0;
 }

 .dt-advanced tbody > tr > .color-cell {
    padding:0 5px 0 0;
 }

 .dataTables_length select {
    width:60px!important;
    padding:6px 0 6px 5px;
 }

.dt-delete-btn.disabled,
.dt-update-btn.disabled,
.dt-info-btn.disabled,
.dt-action-btn.disabled,
.dt-action-tools.disabled {
    display:none;
}

.dtwrap-header .btn-white.btn-primary {
    border-color: inherit;
    font-weight: 300;
}

.hover-show {
    visibility: hidden;
}

.btn.btn-warning {
    color: #fff !important;
}

.dt-advanced tbody > tr:hover .hover-show,
.dt-advanced tbody > tr.selected .hover-show {
    visibility: visible;
 }

.dt-button-collection.dropdown-menu.two-column {
    width:100%;
    max-width:400px;
}

.dt-button-collection.dropdown-menu.two-column li {
    float:left;
    width:50%;
    border:1px #ccc solid;
}

.js-delete-btn.js-dt-bulk-actions {
    display: inline-block;
}

.dataTables_wrapper.dt-bootstrap .dtwrap-header {
    padding: 9px 0 5px 0px;
}

.column-dropdown-scroll {
    height: auto;
    overflow-x: hidden;
    max-height: 400px;
}

.count-col {
    text-align: right;
}

.mobile-dt-search-btn {
    display: none;
}

.js-multi-select-show {
    display: none;
}

.dtwrap-mobile-search {
    display: none;
}

.dtwrap-footer .dataTables_info {
    padding-bottom: 15px;
    padding-left: 15px;
}

@media only screen and (max-width: 415px) {

    .filter-tools .dataTables_filter input {
        display: none;
    }

    .dtwrap-header .dt-buttons {
        margin: 0 5px 0 0;
    }

    .action-tools .dt-buttons {
        margin-left: 15px;
    }

    .dtwrap-mobile-search {
        display: initial;
    }

}

.dtwrap-body table.dataTable thead th {
    white-space: nowrap;
    vertical-align: middle;
}

.table>tbody>tr>td:last-child {
    padding-right: 20px;
}

.table.vertical-center>tbody>tr>td {
    vertical-align: middle;
}

.table>thead>tr>th .fa-question-circle {
    color: #d6d6d6;
}

.contact-info-cell {
    padding-left: 35px;
}

.dt-advanced-right {
    text-align: right;
}
