.el-upload {
    width: 100%;

    .el-upload__input {
        display: none;
    }
}

.el-upload-dragger {
    width: 100%;
}

.el-upload__input {
    visibility: hidden;
}