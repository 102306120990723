$border-color: rgb(223, 229, 236);

.organization-configuration .panel-heading {
    border-bottom: 1px solid;
    border-color: darken($border-color, 5%) !important;
}

.organization-configuration .panel-heading .panel-title {
    font-weight: 600;
}

.organization-configuration .panel-inverse .panel-heading {
    padding: 25px 15px 25px 25px;
}

.panel-body__main-content .el-collapse-item {
    position: relative;
}

.product-card {
    border-bottom: 1px solid rgb(223, 229, 236);
    padding-left: 0;
    padding-bottom: 15px;
}

.product-card:last-child {
    border: none;
}

.product-card__name {
    font-weight: bold;
    padding: 15px 0;
    display: inline-block;
}

.product-card__data-fields {
    padding: 5px 0;
}

.product-actions-dropdown {
    position: absolute;
    top: 10px;
    right: 0;
}

.el-menu{
    border-radius: 2px;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: rgb(237, 239,240);
}

.product-side-menu .el-menu-item {
    height: 40px;
    line-height: 40px;
    color: black;
    border-radius: 5px;
}

.product-side-menu .el-menu-item:hover {
    background-color: transparent;
}

.product-side-menu .el-menu-item.is-active {
    background: #1d4579;
    color: #fff;
}

.panel-body__side-menu {
    width: 25%;
    padding: 20px;
    background-color: #EDEFF0;
}

.panel-body__main-content {
    width: 75%;
    padding: 20px;
}

.side-menu__separator {
    border-color: silver;
}

.product-card__data-fields p {
    margin: 0;
}

.field-list {
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

@media only screen and (min-width: 768px) {
    #products-panel-configuration-title {
        padding: 35px 40px;
    }
}