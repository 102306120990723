.importer-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.importer-header {
    flex: 0 1 auto;
}

.importer-body {
    overflow: auto;
    background: rgb(239, 239, 239);
    padding: 24px 24px 0;
    flex: 1 1 auto;

    display: flex;
    flex-flow: row;
    height: 100%;
}

.importer-body-table-header {
    display: flex;
    justify-content: space-between;
    margin: 25px;
}

.importer-footer {
    background: white;
    border-top: 1px solid rgb(217, 217, 217);
    flex: 0 0 75px;

    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.import-stepper {
    height: 300px;
    min-width: 250px;
    width: 20%;
}

.import-wizard-panel-body {
    padding: 10px;
    height: 100%;
    width: 75%;
}

.import-summary-pane-caret {
    margin-right: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 8px solid rgb(0,
            174,
            255);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 0 dotted;
}

.import-completed {
    color: #90ee90;
}

.import-failed {
    color: #db2e0f;
}

.import-in-progress {
    color: #0f12db;
}

.importer-table-panel-body {
    height: calc(100vh - 350px);
}

.importer-side-panels {
    width: 25%;
    max-width: 350px;
}

.import-summary-details {
    background: #eeefef;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
    padding-top: 20px;
    padding-bottom: 20px;
}

.import-flex-row-space-between {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.import-summary-details-slideout {
    max-width: 400px !important;
}

.m-l-100 {
    margin-left: 100px;
}

.m-r-50 {
    margin-right: 50px;
}

.m-r-100 {
    margin-right: 100px;
}

.m-r-150 {
    margin-right: 150px;
}

.di-btn-color {
    color: rgb(52, 143, 226) !important;
    border-color: rgb(52, 143, 226) !important;
}

.di-template-icons {
    width: 14px;
    margin: 0px 5px 0px 0px;
    opacity: 0.6;
}

// Step 3 of Import Wizard
.di-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
}

.di-uploader {
    padding: 16%;
    height: 100%;
    background: #ffffff;
}

.di-uploaded {
    background-color: #fff;
    padding: 16%;
    height: 100%;
}

.di-upload-demo {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 20px;
}

.di-file-name {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #101820;
    display: block;
    text-align: center;
    font-weight: bold;
}

.di-upload-success-icon {
    font-size: 64px;
    color: #66ABE2;
    position: relative;
    top: 4px;
    margin-bottom: 10px;
}

.di-upload-failure-icon {
    font-size: 64px;
    color: #EB0008;
    position: relative;
    top: 4px;
    margin-bottom: 10px;
}

.di-upload-error-icon {
    font-size: 64px;
    color: #F3D03E;
    position: relative;
    top: 4px;
    margin-bottom: 10px;
}

.di-success-message {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #667A81;
    padding: 20px;
}

.di-failure-message {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #425563;
    margin-bottom: 10px;
}

.di-badge {
    background: transparent;
}

.di-upload-text {
    font-size: 25px !important;
}

.width-half {
    width: 50% !important;
}

.di-data-type-panel-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 18%;
    min-width: 150px;
    margin-right: 20px;
    text-align: center;
}

.di-data-type-panel-button-gray {
    opacity: 0.6
}

.di-data-type-panel-button-selected {
    color: #348fe2;
    background: aliceblue;
    border: 1px solid #348fe2;
    width: 100%;
    border-radius: 3px;
}

.di-data-type-icons {
    width: 50px;
    font-size: 5rem;
}

.di-mapping-quality-panel {
    width: 30%;
    height: 80%;
    background-color: #fef7e5;
    min-width: 250px;
    border-radius: 5px;
}

.di-mapping-information-modal {
    width: 100%;
    height: 100%;
}

.di-list-table {
    height: min-content
}

.di-flex-row {
    display: flex;
    flex-direction: row;
}

.di-flex-column {
    display: flex;
    flex-direction: column;
}

.di-confirmation-text {
    color: rgb(52, 143, 226) !important;
    border-color: rgb(52, 143, 226) !important;
    font-size: medium
}

.di-text-right {
    text-align: end
}

.di-panel-margin {
    padding: 22px 32px;
}

.di-mapping-icons {
    width: 10%;
    scale: .5;
}

.radio-border {
    border: 2px solid #348fe2;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.black {
    color: black;
}

.inactive-agent-user:after {
    color: #d0021b;
    content: " (Agent User) (INACTIVE)";
}

.inactive-user:after {
    color: #d0021b;
    content: " (INACTIVE)";
}

.active-agent-user:after {
    content: " (Agent User)";
}

.back-button-te {
    background: #fff;
    border-color: #348fe2 !important;
    color: #348fe2 !important;
    padding: 10px 20px;
    font-size: 14px;
    margin-left: 10px;
}
