.email-select-dialog-container {
    .email-filter-panel {

        .spinner-overlay {
            background-color: $bg-silver;
        }
    }


    .email-select-folder-menu-col {
        height: 100% !important;
        background-color: $white;
        float: left;
        position: relative;
        width: 15%;
    }

    .email-select-filter-body-col {
        background-color: $bg-silver;
        float: right;
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        .email-filter-panel-body-top-ribbon {
            background-color: $bg-silver;
            box-shadow: none;
        }

        .email-filter-panel {
            padding: 15px;
        }
    }

    .email-select-folder-menu-col + .email-select-filter-body-col {
        width: 85% !important;
    }
}

.email-folder-list {
    list-style-type: none;

    .email-folder-list-item {
        cursor: pointer;
        font-size:14px;
        padding: 10px 15px;

        .active {
            background: $white;
            color: $primary;
        }
    }
}
