.page-header-fixed.no-header{
    padding-top: 0;
}
.page-header-fixed.no-header>.header{
    display: none;
}
.page-header-fixed.no-header>#content.content{
    margin-top: 40px;
}
.page-header-fixed.no-header>.content>.top-ribbon{
    top: 0;
}


.navbar-header{
    .app-switcher{
        display: inline-block;
        float:left;
        position: relative;
        top: 16px;
        li{
            list-style: none;
          i{
              font-size:1.5em;
          }
        }
    }
}

@media screen and (max-width: 768px) {
    .navbar-header {
        max-width: 280px;
        .app-switcher {
            display: none;
        }
    }
}

#header-switch-user-include-inactive {
    padding-left: 15px;
    position: relative;
    float: right;
}

#header-switch-user-include-inactive input {
    position: absolute;
    left: 0;
    top: -2px;
}