.upm-list-header {
    display: flex;

    .upm-list-header-item {
        flex-direction: row;
    }

    .upm-list-header-title {
        max-width: 175px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}