.btn {
    font-weight: 400 !important;

    .el-checkbox {
        margin-bottom: 0;
    }

    &.btn-white {
        border-color: #ccd0d4;
    }

    &.btn-primary {
        color: #fff !important;
        /*background: #348fe2;
        border-color: #348fe2;*/
    }

    &.btn-default {
        color: $text-black;
    }

    &.btn-link {
        color: $primary;
        text-decoration: none;

        &:hover {
            background: $bg-silver;
        }

        &:active {
            box-shadow: none;
        }

        &:disabled,
        &:disabled:hover {
            color: $text-disabled-input-color;
            background: none;
        }
    }

    &.btn-bordered {
        border: solid 1px !important;
    }
}

.btn-container {
    font-size: 0;

    .btn + .btn {
        margin-left: 5px;
    }
}

.dialog-footer.btn-container {
    font-size: 0;

    .btn + .btn {
        margin-left: 10px;
    }
}

.btn__avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.btn__social-avatar-icon {
    vertical-align: bottom;
    margin-left: -10px;
    font-size: 8px;
}

.btn__ellipsis{

}

a.btn.btn-primary.btn-sm.navbar-btn:hover {
    color: #fff !important;
    background: #2a72b5;
    opacity: 1;
}

.btn .mobile {
    display: none;
}

.btn-icon {
    width: 35px;
}

.btn-group > .btn + .el-dropdown {
    padding-right: 0;
}

.btn-group > .el-dropdown > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;

    &.btn-primary {
        border-left-color: #2a72b5;
    }

    &.dropdown-toggle {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.btn-icon.btn.btn-icon.btn-help {
    vertical-align: text-bottom;
    font-size: 10px;
}

.btn-empty-content {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
}

.btn-help {
    border: 1px solid #ccd0d4 !important;
}

.btn-close {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    font-size: 16px;

    i {
        color: rgb(191, 201, 217);
    }
}

@media only screen and (max-width: 415px) {

    .btn .mobile {
        display: block;
    }

    .btn .desktop {
        display: none;
    }
}