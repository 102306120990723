
.sms-modal-top-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.sms-modal-row {
  margin-bottom: 10px;
}

.sms-modal-header {
  margin-bottom: 5px;
  margin-top: 5px;
}

.sms-opt-in-modal-full-width {
  margin-left: -15px;
  margin-right: -15px;
}

.sms-opt-in-tabs .el-tabs__header {
  display: none !important;
}

.sms-opt-in-steps {
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: 15px;
}
