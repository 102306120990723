ul#notifications{
    width:280px;
    max-width:280px;
}

ul#notifications > li.media > div.media-body{
    position:relative;
}

ul#notifications > li.media{
    padding:10px;
}

ul#notifications > li.media > div.media-body > div.text-muted{
    height:auto;
    max-height:36px;
    font-size:12px;
    line-height:12px;
    overflow:hidden;
}

ul#notifications > li.media > div.text-muted a{
    display:inline;
}

ul#notifications > li.media > div.media-body > a.dismiss-notification{
    position:absolute;
    right:0;
    top:0;
    display:block;
    padding:0px;
}

table#broadcast-notifications-table td.notification-icon-col{
    width:40px;
    text-align:center;
    vertical-align:middle;
}

table#broadcast-notifications-table td.notification-icon-col .media-object{
    border-radius:50%;
    color:#FFFFFF;
    font-size:25px;
    width:36px;
    height:36px;
    text-align:center;
    line-height:37px;
}