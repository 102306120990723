.select2-container .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    height: auto !important;
    border-color: $input-border-color !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: $input-base-font-size;
    color: $text-color;
}

.select2.select2-container.select2-container--default.select2-container--below,
.select2-container {
    outline: none;
}

.select2-dropdown {
    -webkit-box-shadow: $dropdown-shadow !important;
    box-shadow: $dropdown-shadow !important;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background: $dropdown-hover-color;
}

.select2-results__option {
    color: $text-black;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: rgba(52,143,226,.1);
    border-color: rgba(52,143,226,.2);
    color: $primary-color;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: $primary-color;
}


/*TAGIT FIX*/
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: inherit;
    font-size: $input-base-font-size;
}