h2.nm-header {
    text-align: center;
    margin: 10px 5px 30px 5px;
}

.nm-container {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 2px solid transparent;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    cursor: pointer;
    position: relative;
}

.nm-container:hover {
    border-color: #348fe2;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
}

.nm-img {
    font-size: 6em;
    margin: 15px auto;
    display: table;
    color: #348fe2;
}

.nm-img img {
    width: 76px;
    height: auto;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}

.nm-img i {
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}


h4.nm-title {
    margin: 0px auto;
    padding-bottom: 25px;
    display: table;
}


/* guides
================================================== */

.guide__info {
    color: #c8c8c8;
}

.guide__progress {
    background: #fff;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 58px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 0 10px;
}

.step {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.step:first-of-type.complete-exercise:before {
    display: block;
}

.step:first-of-type:before, .step:last-of-type:before, .trails-progress .trail .step.next-up-exercise:before {
    display: none;
}

.step.complete-exercise:before, .step.next-up-exercise:before {
    background: rgba(0,0,0,0.1);
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    right: 0;
    top: 48%;
    width: 100%;
}

.dot {
    height: 14px;
    width: 14px;
    background: #D8D8D8;
    border-radius: 14px;
    margin: 14px;
    position: relative;
}

.complete-exercise .dot {
    background: #4dd1aa;
    cursor: pointer;
}

.next-up-exercise .dot {
    background: #4A90E2;
}

.dot:before {
    height: 14px;
    width: 14px;
    border-radius: 14px;
    content: "";
    position: absolute;
    z-index: 0;
}

.in-progress-exercise .dot:before, .next-up-exercise .dot:before {
    -webkit-animation: radiate 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s forwards;
    animation: radiate 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 1;
}

.guide__title {
    display: inline-block;
    padding-right: 10px;
}

.guide__info {
    color: #348fe2;
    font-size: 18px;
}

.guide__progress-step {
    color: #999;
    float: right;
    font-weight: 500;
    margin-top: 8px;
    margin: 0 30px 0 0;
}

/* guide form
================================================== */

.guide-form {
    background: #fff;
    border-radius: 3px;
    padding: 20px;
    margin-top: 20px;
}

.json-form-textarea.form-control {
    height: 400px;
}

/* help/guides
================================================== */

.nm-only-title {
    margin: 0px auto;
    padding: 50px 0;
    display: table;
}

.nm-container--complete {
    position: relative;
    border-color: #348fe2;
}

.nm-container__check {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #348fe2;
}