$vertical-tab-width: 72px;
$vertical-tab-icon-size: 24px;

.vertical-tabs {
    display: flex;
    height: 100%;
}

.vertical-tabs__nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: $white;
    position: relative;
    transition: transform .3s;
    border-right: 2px solid $table-border-color;
    width: $vertical-tab-width;

    .el-tabs__active-bar {
        width: $vertical-tab-width;
        right: 0px;
        top: 0px;
        background: transparent;
        display: flex;

        .el-tabs__active-bar-fill {
            height: 100%;
            width: 70px;
            background: $primary;
            opacity: 0.1;
        }

        .el-tabs__active-bar-border {
            height: 100%;
            width: 2px;
            background: $primary;
        }
    }
}

.vertical-tab-pane {
    flex-grow: 1;
    min-width: 352px;
    position: relative;
}

.vertical-tabs__nav-item {
    background: $white;
    position: relative;
    height: $vertical-tab-width;
    width: $vertical-tab-width;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: $primary;
    position: relative;
    cursor: pointer;
    border-right: 2px solid $table-border-color;
}

.vertical-tabs__nav-item-icon {
    width: $vertical-tab-icon-size;
    height: $vertical-tab-icon-size;
    display: inline-block;
    vertical-align: text-bottom;
    box-sizing: border-box;
    margin-bottom: 4px;

    i {
        font-size: $vertical-tab-icon-size;
    }
}

.vertical-tabs__nav-item-label {
    padding: 0 4px;
    text-align: center;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 18px;
    line-height: 18px;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}