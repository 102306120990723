.push-config-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.push-config-header {
    flex: 0 1 auto;
}

.push-config-body {
    overflow: auto;
    background: rgb(239, 239, 239);
    margin-bottom: 15px;
    flex: 1 1 auto;

    display: flex;
    flex-flow: row;
    height: 100%;
}

.push-config-stepper {
    margin-left: 5%;
    height: 150px;
    min-width: 300px;
}

.push-config-panel-body {
    padding: 10px;
    height: 100%;
    width: 75%;
}

.pc-panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    width: 990px;
}

.push-config-footer {
    padding: 10px 10px 10px 0px;
    background: white;
    height: 100%;
    display: flex;
}
