$sidebar-width: 220px;
$side-menu-width: 339px;
$side-menu-tabs-width: 424px;

.side-menu {
    position: fixed;
    bottom: 0;
    top: 98px;
    width: $side-menu-width;
    z-index: 1001;

    &.is-open.left {
        /*for main sidebr close animation*/
        transition: left, width 0.3s ease !important;
    }

    &.side-menu--vertical-tabs {
        width: $side-menu-tabs-width;
    }
}

body.sidebar-open .side-menu.left {
    left: $sidebar-width;
}

.side-menu.left {
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(-339px);

    &.transition {
        -webkit-transition-property: width,-webkit-transform;
        transition-property: transform,width,-webkit-transform;
        -webkit-transition-duration: .1s;
        transition-duration: .1s;
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }
}

.side-menu.right {
    right: 0;
    -webkit-transform: translateX(-1 *$side-menu-width);
    transform: translateX(-1 * $side-menu-width);

    &.transition {
        -webkit-transition-property: width,-webkit-transform;
        transition-property: transform,width,-webkit-transform;
        -webkit-transition-duration: .1s;
        transition-duration: .1s;
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }
}

.side-menu.is-open.right {
    -webkit-box-shadow: 0 0 6px rgba(0,0,0,.4);
    box-shadow: 0 0 6px rgba(0,0,0,.4);
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.side-menu.is-open.left {
    -webkit-box-shadow: 0 0 6px rgba(0,0,0,.4);
    box-shadow: 0 0 6px rgba(0,0,0,.4);
    -webkit-transform: translateX(0);
    transform: translateX(0);
}


.side-menu__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.side-menu__content {
    background-color: $white;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    overflow: hidden;
}

.content.side-menu-open {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    &.transition {
        -webkit-transition: margin .1s ease-in;
        transition: margin .1s ease-in;
    }
}

//body.sidebar-open .content.side-menu-open.left {
//    margin-left: $side-menu-width + $sidebar-width;
//}

.content.side-menu-open.left {
    margin-left: $side-menu-width;
}

.content.side-menu-open.right {
    margin-right: $side-menu-width;
}

.side-menu__content-header {
    padding: 15px 10px;
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
}

.side-menu__title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    color: $text-black;
}

.side-menu__content-body {
    padding: 15px 10px;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.side-menu__content-footer {
    padding: 15px 10px;
    width: 100%;
    border-top: 1px solid #d6d6d6;
}

.side-menu-panel {
    padding: 15px;

    &:not(:last-child) {
        border-bottom: 1px solid $input-border-color;
    }

    h4 {
        font-weight: 700;
        margin-bottom: 15px;
        display: inline-block;
    }
}

.side-menu-panel__header{
    padding-bottom: 15px;

    h4 {
        padding: 0;
        margin: 0;
    }
}

.side-menu-panel__subtitle{
    padding-top: 5px;
}

.side-menu-panel__header-right{
    margin-left: auto;
}

.el-dialog {

    .side-menu {
        position: absolute;

        &.left {
            left: 0 !important;
        }
    }
}